import { configureStore } from "@reduxjs/toolkit";
import navigationSlice from './navigation'
import spinnerReducer from './spinner';

export const store = configureStore({
  reducer: {
    navigation: navigationSlice,
    spinner: spinnerReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;