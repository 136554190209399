import * as Yup from 'yup';


const AlarmsSetupSchema = Yup.object().shape({
  position: Yup
    .string(),
    // .required("patients.pair.error.positionRequired"),
  sys_hr_monitoring_status: Yup.bool(), 
  sys_hr_monitoring_min: Yup
    .number()
    .when('sys_hr_monitoring_status', {
      is: true,
      then: ()=>Yup.number().required("patients.pair.error.hrMinRequired")
    }),
  sys_hr_monitoring_max: Yup
    .number()
    .when('sys_hr_monitoring_status', {
      is: true,
      then: ()=>Yup.number().required("patients.pair.error.hrMaxRequired")
    }),
  sys_temperature_monitoring_status: Yup.bool(),
  sys_temperature_monitoring_min: Yup
    .number()
    .when('sys_temperature_monitoring_status', {
      is: true,
      then: ()=>Yup.number().required("patients.pair.error.temperatureMinRequired")
    }),
  sys_temperature_monitoring_max: Yup
    .number()
    .when('sys_temperature_monitoring_status', {
      is: true,
      then: ()=>Yup.number().required("patients.pair.error.temperatureMaxRequired")
    }),
  sys_triggerValue: Yup.bool(),
  sys_sendMail: Yup.bool(),
  sys_sendSMS: Yup.bool(),
  sys_mail: Yup
    .string()
    .when('sys_sendMail', {
      is: true,
      then: ()=>Yup.string().required("patients.pair.error.emailRequired")
    }),
    sys_smsNo: Yup
    .string()
    .when('sys_sendSMS', {
      is: true,
      then: ()=>Yup.string().required("patients.pair.error.smsRequired")
    })

});

export default AlarmsSetupSchema;