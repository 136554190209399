import { useTranslation } from "react-i18next"

const Component:React.FC<{
  type?: "danger"| "success"| "warning",
  text?: string,
  raw?: boolean,
  onClick?: () => void
}> = ({type, text, raw, ...rest}) => {
  const {t} = useTranslation()
  return text && text.length > 0 ? (
      <div className={`alert alert-${type}`} {...rest} role="alert">
        <p>{raw ? text : t(text)}</p>
      </div>
  ) : null
}


export default Component

