import { Form, Formik } from "formik"
import PageLayout from "../../components/layout/Page"
import { useTranslation } from "react-i18next"
import Input from "../../components/elements/Input"
import Checkbox from "../../components/elements/Checkbox"
import * as Yup from "yup"
import { useAuthUser } from "react-auth-kit"
import { useCustomerInfo, useUserProfile, useUserProfileMutation } from "../../api/hooks"
import { AuthUser } from "../../types"
import { useContext, useEffect, useState } from "react"

import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/spinner';
import { UserRoleContext } from "../../providers/UserRoleProvider"
import Alert from "../../components/elements/Alert"


const ProfileSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  alarmEmail: Yup.string() 
  .email('profile.error.alarmEmail'),
  alarmSms: Yup.string() 
  .matches(/^(?:(?:\+420|00420)?[6-7][0-9]{8}|(?:\+421|00421)?9[0-9]{8})$/, 'profile.error.alarmSms'),
  agreed: Yup.boolean()
    .oneOf([true], 'profile.error.aggreedToTerms')
    .required('profile.error.aggreedToTerms'),
  currentPassword: Yup.string(),
  newPassword: Yup.string()
    .min(8, 'profile.error.newPasswordMin')
    .max(30, 'profile.error.newPasswordMax'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'profile.error.confirmNewPassword')
})

const translatedRole:any =  {
  "admin": "Administrátor",
  "caretaker": "Pečovatel",
  "ambulance": "Ambulance"
}

type Notification = {
  text?: string
  type?: "danger" | "warning" | "success"
}

const Content = () => {
  const auth = useAuthUser()
  const customer = useCustomerInfo(auth()?.customerId)
  const { userRole } = useContext(UserRoleContext);

  const [notification, setNotification] = useState<Notification>({})


  useEffect(()=>{
    setNotification({})
  }, [])

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    alarmEmail: '',
    alarmSms: '',
    agreed: false,
    ambulanceName: customer?.data?.additionalInfo?.description || '',
    userRole: translatedRole[userRole],
  })


  const { t } = useTranslation()
  const {data} = useUserProfile(auth()?.userId)

  const { mutateAsync } = useUserProfileMutation()
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...initialValues,
        firstName: data.user.firstName || '',
        lastName: data.user.lastName || '',
        alarmEmail: data.attributes.sys_alarm_email || '',
        alarmSms: data.attributes.sys_alarm_sms || '',
        agreed: data.attributes.sys_agreed_to_terms || false,
      })
    }
  }, [data])


  return (
    <div className="container">
      <Alert type={notification.type} text={notification.text} />
      <br/>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={ProfileSchema}
        onSubmit={async (values, { setSubmitting }) => { 
          setSubmitting(true)
          dispatch(startLoading());
          try {
            const {firstName, lastName, currentPassword, newPassword, alarmEmail, alarmSms, agreed} = values
            const payload = {
              authUser: auth() as AuthUser,
              data: {
                firstName, 
                lastName, 
                currentPassword,
                newPassword, 
                confirmNewPassword: '',
                alarmEmail, 
                alarmSms,
                agreed: agreed || false,
              }
            }
            await mutateAsync(payload)
            
          } catch (error: any) {
            setNotification({type: "danger", text: error.message})
          } finally {
            setNotification({type: "success", text: t('profile.form.success')})
            setSubmitting(false)
            dispatch(stopLoading());
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
         <Form>
            <div className="form-group row">
              <label
                htmlFor="ambulanceName"
                className="required col-md-5 control-label"
              >
              {t('profile.form.ambulanceName')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  disabled
                  name="ambulanceName"
                  />
                <span className="help">
                {t('profile.form.fieldIsNotChangable')}
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="userRole"
                className="required col-md-5 control-label"
              >
              {t('profile.form.userRole')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  disabled
                  name="userRole"
                  />
                <span className="help">
                {t('profile.form.fieldIsNotChangable')}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="firstName"
                className="required col-md-5 control-label"
              >
              {t('profile.form.firstName')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Anna"
                  name="firstName"
                  />
                <span className="help">
                {t('profile.form.fieldIsPrivate')}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="lastName"
                className="required col-md-5 control-label"
              >
              {t('profile.form.lastName')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Doktorova"
                  name="lastName"
                  />
                <span className="help">
                {t('profile.form.fieldIsPrivate')}
                </span>
              </div>
            </div>

            <br/>

            <div className="form-group row">
              <label
                htmlFor="currentPassword"
                className="required col-md-5 control-label"
              >
              {t('profile.form.currentPassword')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="currentPassword"
                  placeholder="********"
                  name="currentPassword"
                  />
                <span className="help">
                {t('profile.form.currentPasswordDescription')}
                </span>
              </div>
              </div>

          {values.currentPassword && (
            <> 
              <div className="form-group row">
              <label
                htmlFor="newPassword"
                className="required col-md-5 control-label"
              >
              {t('profile.form.newPassword')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="newPassword"
                  placeholder="********"
                  name="newPassword"
                  />
                <span className="help">
                {t('profile.form.newPasswordDescription')}
                </span>
              </div>
              </div>
            
              <div className="form-group row">
                <label
                  htmlFor="confirmNewPassword"
                  className="required col-md-5 control-label"
                >
                {t('profile.form.confirmNewPassword')}
                </label>
                <div className="col-md-7">
                  <Input
                    className="form-control"
                    type="password"
                    placeholder="*********"
                    name="confirmNewPassword"
                    required
                    />
                  <span className="help">
                  {t('profile.form.fieldIsPrivate')}
                  </span>
                </div>
              </div>
            </>


          )}

            <br/>



            <div className="form-group row">
              <label
                htmlFor="alarmEmail"
                className="required col-md-5 control-label"
              >
              {t('profile.form.alarmEmail')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="email"
                  placeholder="email@pro-notifikace.cz"
                  name="alarmEmail"
                  />
                <span className="help">
                {t('profile.form.alarmEmailDescription')}
                </span>
              </div>
            </div>
        
            <div className="form-group row">
              <label
                htmlFor="alarmSms"
                className="required col-md-5 control-label"
              >
              {t('profile.form.alarmSms')}
              </label>
              <div className="col-md-7">
                <Input
                  className="form-control"
                  type="tel"
                  placeholder="+420 987 654 321"
                  name="alarmSms"
                  />
                <span className="help">
                {t('profile.form.alarmSmsDescription')}
                </span>
              </div>
            </div>

            <br/>
            
            <div className="row">
              <div className="col-8">
                <Checkbox 
                  name="agreed"
                  label={t('profile.form.agree')}
                  required={true}
                />
              </div>
              <div className="col-4">
                <button
                  className="btn btn-primary pull-right"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                    {t('profile.form.save')}
                </button>
              </div>
            </div>
          </Form>
        )}
       
       
      </Formik>
    </div>
  )
}


const ProfilePage = () => {
  return (
    <PageLayout >
      <Header />
      <Content />
    </PageLayout>
  )
}


const Header = () => {
  const { t } = useTranslation()

  return (
    <div className="jumbotron">
      <div className="m-b-20">
        <div className="container">
          <div className="row m-0">
            <div className="col-xl-12 col-lg-12">
              <div className="card card-transparent">
                <div className="card-header ">
                  <div className="card-title">{t('profile.jumbotron.title')}</div>
                </div>
                <div className="card-body">
                  <p>
                  {t('profile.jumbotron.description')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ProfilePage