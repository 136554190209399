import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAlarms } from "../../api/hooks";
import { Alarm } from "../../types";
import { useEffect } from "react";

const AlarmTable = ()=> {
  const {data, isLoading, refetch, isRefetching} =  useAlarms(7, "ANY")

  useEffect(() => {
    const update = async () => {
      await refetch()
    }
    update()
  }, [])

  const {t} = useTranslation()
  return (
    <div className="widget-11 card widget-loader-bar">
    <div className="card-header">
      <div className="card-title">{t('dashboard.alarmsTable.latest')}</div>
      <div className="card-controls">
        <ul>
          <li>
            <a
              data-toggle="refresh"
              className={`card-refresh`}
              href="#"
              onClick={async (e) => {
                await refetch()
              }}
            >
              <i
                className={`card-icon card-icon-refresh`}
              ></i>
              <i
                className={`card-icon-refresh-lg-white-animated `}
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "20px",
                }}
              ></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="p-l-20 p-r-20 p-b-10 p-t-5">
      <div className="pull-left">
        <h3 className="text-danger no-margin">{t('dashboard.alarmsTable.title')}</h3>
      </div>
      <div className="clearfix"></div>
    </div>
  
    <div className="widget-11-table auto-overflow">
      <table className="table table-condensed table-hover">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={4} style={{textAlign: "center"}}>{t('dashboard.alarmsTable.emptyTable')}</td>
            </tr>
          )}

          {isRefetching && (
           <tr>
            <td colSpan={4} style={{textAlign: "center"}}>
              <div className="progress-circle-indeterminate"></div>
            </td>
          </tr>
          )}

          {!isRefetching && data?.data.map((alarm:Alarm)=> (
            <tr key={alarm.id.id}>
              <td className="b-r b-dashed b-grey" colSpan={2} >
                <span  className="hint-text small"> {t('alarms.type.'+alarm.type)}</span>
              </td>
              <td colSpan={1} className="b-r b-dashed b-grey" >
                <span className="font-montserrat fs-18">{new Date(alarm.createdTime).toLocaleDateString()}</span>
              </td> 
              <td className="b-r b-grey " colSpan={1}>
                <span className="hint-text small"> {alarm.details.sys_pacientName || "-"}</span>
              </td>
            </tr>
          ))}
            
        </tbody>
      </table>
    </div>
    <div className="p-t-15 p-b-15 p-l-20 p-r-20">
      <p className="small no-margin">
        <span className="hint-text ">
          {t('dashboard.alarmsTable.showAll')} {"  "}
          </span>
        <NavLink
          to="/alarms"
          className="btn-circle-arrow b-grey"
        >
          <i className="pg-icon">chevron_right</i>
        </NavLink>
          
      </p>
    </div>
  </div>
  )
}


export default AlarmTable