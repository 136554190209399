import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { apiClient } from '../api/calls';

export enum UserRole {
  Admin = "admin",
  Ambulance = "ambulance",
  Caretaker = "caretaker",
}

const ROLE_KEY = 'sys_role';


interface UserRoleContextType {
  userRole: UserRole;
  setUserRole: (role: UserRole) => void;
}

export const UserRoleContext = createContext<UserRoleContextType>({
  userRole: UserRole.Ambulance,
  setUserRole: () => {},
});

interface UserRoleProviderProps {
  children: ReactNode;
}

export const UserRoleProvider = ({ children }: UserRoleProviderProps) => {
  const [userRole, setUserRole] = useState<UserRole>(UserRole.Ambulance);


  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const userId = auth()?.userId;
  
  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated()) {
        const url = `/plugins/telemetry/USER/${userId}/values/attributes?keys=${ROLE_KEY}`;
        const response = await apiClient.get(url);
        const data = response.data;
        const attribute = data.find((item: any) => item.key === ROLE_KEY);
        if (attribute) {
          // Set user role based on attribute value
          const role = attribute.value as UserRole;
          setUserRole(role);
        }
      }
    };
  
    fetchUserRole();
  }, [isAuthenticated, userId]);

  return (
    <UserRoleContext.Provider value={{ userRole, setUserRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

