import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import TotpForm from './TotpForm';
import TotpConifgForm from './TotpConfigForm';

/**
 * Two Factor Authentication
 *  
 * first we check the credentials, 
 * then we send the 2FA code
 */
const Authentication = ()=>{
  const [verificationToken, setVerificationToken] = useState("");
  const [loginValues, setLoginValues] = useState({})
  const [totpToken, setTotpToken] = useState("");
  return (
    totpToken ? 
      <TotpConifgForm 
        totpToken={totpToken}
        setVerificationToken={setVerificationToken}
        loginValues={loginValues}
      /> :
      verificationToken ? 
        <TotpForm
          setVerificationToken={setVerificationToken}
          verificationToken={verificationToken}
        /> : 
        <LoginForm 
          setVerificationToken={setVerificationToken}
          setTotpToken={setTotpToken}
          setLoginValues={setLoginValues}
        />
  );
}

const Login = () => {
  const { t } = useTranslation();
  return (
    <div className="login-wrapper ">
      <div className="bg-pic">
        <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/neurosmart-white.png"}
              alt="neurosmart"
              className="brand m-b-20"
              height="80"
            />
          <p className="small">
            {t('login.bannerClaim')}
          </p>
        </div>
      </div>
      <div className="login-container bg-white vh-100">
        <div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/soitron-logo.png"}
            alt="logo"
            height="48"
          />
          <h2 className="p-t-25">
            {t('login.title')}
          </h2>
          

          <Authentication/>

          <div className="pull-bottom sm-pull-bottom">
            <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
              <div className="col-sm-9 no-padding m-t-10">
                <p className="small-text normal hint-text">
                {t('login.copyright')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;