import React, { ReactNode } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import {Outlet, Navigate} from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'

interface BaseProps {
  children: ReactNode;
}

const Base = ({ children }: BaseProps) =>  {
  let isAuthenticated = useIsAuthenticated()

  if(!isAuthenticated()){
    return <Navigate to="/login"/>
  }

  return (
    <div className="h-100">
      <Header/>
      <Sidebar/>
      <div className="page-container">
        <div className="page-content-wrapper ">
        {children}
        </div>
      </div>
    </div>
  )
}

export default Base