import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SidebarItem from '../elements/SidebarItem';
import { useContext } from 'react';
import { UserRoleContext, UserRole } from '../../providers/UserRoleProvider';


const selectToggle = (state:any) => state.active

const Component = ()=>{

  const {userRole} = useContext(UserRoleContext)

  const { t } = useTranslation()

  const toggleValue = useSelector(selectToggle)

  if(toggleValue) {
    document.body.classList.remove('dashboard')
    document.body.classList.add('mac', 'desktop', 'js-focus-visible', 'sidebar-open', 'sidebar-visible')
  } else {
      document.body.classList.remove('mac', 'desktop', 'js-focus-visible', 'sidebar-open', 'sidebar-visible')
      document.body.classList.add('dashboard')
  }


  const sidebarItems = [
    {
      url: '/',
      title: t("sidebar.dashboard"),
      icon: <i className="pg-icon">home</i>,
      subTitle: t('sidebar.dashboardSubtitle'),
      topMargin: 'm-t-20',
      roles: [UserRole.Ambulance, UserRole.Caretaker]
    },
    {
      url: '/patients',
      title: t("sidebar.patients"),
      icon: <i className="pg-icon">person</i>,
      subTitle: t('sidebar.patientsSubtitle'),
      roles: [UserRole.Caretaker, UserRole.Ambulance, UserRole.Caretaker]
    },
    {
      url: '/alarms',
      title: t("sidebar.alarms"),
      icon: <i className="pg-icon">warning</i>,
      subTitle: t('sidebar.alarmsSubtitle'),
      roles: [UserRole.Ambulance, UserRole.Caretaker]
    },
    {
      url: '/devices',
      title: t("sidebar.devices"),
      icon: <i className="pg-icon">database</i>,
      subTitle: t('sidebar.devicesSubtitle'),
      roles: [UserRole.Admin, UserRole.Ambulance]
    },
    {
      url: '/caretakers',
      title: t("sidebar.caretakers"),
      icon: <i className="pg-icon">users</i>,
      subTitle: t('sidebar.caretakersSubtitle'),
      roles: [UserRole.Admin, UserRole.Ambulance]
    },
    {
      url: '/admin/patients',
      title: t("sidebar.patients"),
      icon: <i className="pg-icon">settings</i>,
      subTitle: t('sidebar.adminPatientsSubtitle'),
      roles: [UserRole.Admin]
    },
    {
      url: '/admin/ambulances',
      title: t("sidebar.ambulances"),
      icon: <i className="pg-icon">settings</i>,
      subTitle: t('sidebar.ambulancesSubtitle'),
      roles: [UserRole.Admin]
    },
  ];

  // Only include items the user has access to
  const accessibleItems = sidebarItems.filter(item => item.roles.includes(userRole));


  return (
    <nav className={`page-sidebar ${toggleValue ? 'visible' : ''}`}>
      <div className="sidebar-header">
       <img
          src={process.env.PUBLIC_URL + "/assets/img/soitron-logo.png"}
          alt="logo"
          className="brand"
          height="40"
        />
      </div>
      <div className="sidebar-menu">
        <ul className="menu-items scroll-content scroll-scrolly_visible">
          {accessibleItems.map((item, index) => (
            <SidebarItem
              key={index}
              url={item.url}
              title={item.title}
              icon={item.icon}
              subTitle={item.subTitle}
              topMargin={item.topMargin}
            />
          ))}
        </ul>
        {/* ... */}
      </div>
    </nav>
  );
};


export default Component