import { createSlice, configureStore } from '@reduxjs/toolkit'

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    active: false
  },
  reducers: {
    toggleMenu: state=> {
      state.active = !state.active
    }
  }
})

export const {toggleMenu } = navigationSlice.actions
export default navigationSlice.reducer