import React, { useEffect } from 'react';
import { LatLngExpression, LatLngBoundsLiteral, LatLngTuple } from 'leaflet';
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';

interface DeviceMapProps {
  positions: any[]
}

const DEFAULT_MAP_POSITION: LatLngExpression = [49.195061, 16.606836];
const DEFAULT_MAP_ZOOM = 12;

const ChangeView = ({ bounds }: { bounds: LatLngBoundsLiteral }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [bounds, map]);
  return null;
};

const DeviceMap: React.FC<DeviceMapProps> = ({ positions }) => {
  let lastKnownPosition: LatLngTuple | null = null;
  const filteredPositions = positions.filter((pos) => pos.latitude != null && pos.longitude != null);
  const totalCount = filteredPositions.length;

  const bounds = filteredPositions.reduce<LatLngTuple[]>((boundsArray, pos) => {
    const currentPos: LatLngTuple = [parseFloat(pos.latitude), parseFloat(pos.longitude)];
    if (currentPos[0] !== 0 || currentPos[1] !== 0) {
      boundsArray.push(currentPos);
      lastKnownPosition = currentPos;
    } else if (lastKnownPosition !== null) {
      boundsArray.push(lastKnownPosition);
    }
    return boundsArray;
  }, []);

  return (
    <MapContainer
      style={{ height: "500px", width: "100%" }}
      center={DEFAULT_MAP_POSITION}
      zoom={DEFAULT_MAP_ZOOM}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://naramky.neurosmart.cz/osm_tiles/{z}/{x}/{y}.png" />
      <ChangeView bounds={bounds} />
      <Polyline positions={bounds} color="red" />
      {bounds.map((position, index) => (
        <Marker position={position} key={filteredPositions[index].ts}>
          <Popup>
            Latitude: {position[0]} <br/>
            Longitude: {position[1]} <br/>
            Timestamp: {new Date(filteredPositions[index].ts).toLocaleString()} <br/>
            Index: {index+1}/{totalCount}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default DeviceMap;
