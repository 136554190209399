import React from "react";
import { useNavigate } from "react-router-dom";

const Progress = ()=>{
  return (
    <React.Fragment>
      <div
        className="card-progress"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", display: "block" }}
      ></div>
    </React.Fragment>

  )
};


const RefreshLink = () => {
  return (
    <ul>
      <li>
        <a
          data-toggle="refresh"
          className={`card-refresh`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <i
            className={`card-icon card-icon-refresh `}
          ></i>
          <i
            className={`card-icon-refresh-lg-white-animated`}
            style={{
              position: "absolute",
              top: "14px",
              right: "20px",
            }}
          ></i>
        </a>
      </li>
    </ul>
  )
}


const Component: React.FC<{
  title: string
  children?: any
  description: string
  className?: string
  isLoading: Boolean
  url: string
}> = ({ isLoading, url, title, children, description, className=" bg-complete ", ...rest }) => {

  const navigate = useNavigate();

  const handleComponentClick = () => {
    navigate(url);
  }

  return (
    <div className="ar-1-1" onClick={handleComponentClick}>
      <div className={`${className} card no-margin widget-loader-bar`}>
        <div className="card-header ">
          <div className="card-controls">
            <RefreshLink />
          </div>
        </div>
        <div className="card-body">
          <div className="pull-top top-left top-right padding-25">
            <h4 className="text-white semi-bold">
              {title}
            </h4>
            <h2 className="m-t-20">
              {children}
            </h2>
            <p className="hint-text m-t-30 no-margin">
              {description}
            </p>
          </div>

        </div>
        {isLoading ? <Progress/> : null}
      </div>    
    </div>
  )
}

export default Component

