import { useField } from "formik";
import { LatLngExpression } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { FeatureGroup, MapContainer, TileLayer, Circle, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { distance } from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { circle as turfCircle, bbox as turfBbox } from '@turf/turf';


interface Props {
  center: LatLngExpression;
  zoom: number;
  name: string;
}


const ChangeView = ({ circles }: { circles: any }) => {
  const map = useMap();
  const [bounds, setBounds] = useState<any>([])
  
  useEffect(() => {
    const bounds = circles.reduce((boundsArray: any, circle: any) => {
      
      const currentPos: LatLngExpression = [parseFloat(circle.lat), parseFloat(circle.lng)];
      if (currentPos[0] !== 0 || currentPos[1] !== 0) {
        const buffer = turfCircle(currentPos, circle.radius, { units: 'meters' });
        const bufferBbox = turfBbox(buffer);
        boundsArray.push([[bufferBbox[0], bufferBbox[1]], [bufferBbox[2], bufferBbox[3]]])
      }
      return boundsArray;
    }, []);

    setBounds(bounds)
  }, [circles])

  useEffect(() => {
    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [bounds, map]);
  return null;
};

const InputMap: React.FC<Props> = ({ center, zoom, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [existingCircles, setExistingCircles] = useState<any>([]);
  const featureGroupRef = useRef(null);
  const [circleColor, setCircleColor] = useState('#ff0000');
  const [newCircle, setNewCircle] = useState<{ circle: any, layer: any } | null>(null);
  const [alarmType, setAlarmType] = useState('both'); // 'enter', 'exit', or 'both'
  const prevFieldValueRef = useRef(field.value);
  const prevExistingCirclesRef = useRef(existingCircles);

  useEffect(() => {
    if(!meta.touched && meta.initialValue && (meta.value === '' || meta.value === '[]')) {
      helpers.setTouched(true);
      setExistingCircles(JSON.parse(meta.initialValue));
      return
    } 

    if((meta.value === '' || meta.value === '[]') && meta.initialValue === '' && existingCircles.length > 0){
      setExistingCircles([]);
    }
  }, [meta.initialValue, meta.value])


  useEffect(() => {
    if (alarmType === 'out') {
      setCircleColor('#cd2121');
    } else if (alarmType === 'in') {
      setCircleColor('#19ad79');
    } else {
      setCircleColor('#7252d3');
    }

  }, [alarmType])

  const getCircleColorByAlarmType = (alarm: string) => {
    switch (alarm) {
      case 'out':
        return '#cd2121';
      case 'in':
        return '#19ad79';
      default:
        return '#7252d3';
    }
  }

  function isDifferent(a: any, b: any) {
    return JSON.stringify(a) !== JSON.stringify(b);
  }

  useEffect(() => {
    const el = featureGroupRef.current as any;
    // Check if the current value is different from the previous value
    if (isDifferent(field.value, prevFieldValueRef.current)) {
      if (!field.value && el && el._layers) {
        if (Object.keys(el._layers).length > 0) {
          el.clearLayers();
        }
      } else if (field.value) {
        const parsedCircles = JSON.parse(field.value);
        setExistingCircles(parsedCircles);
      }
    }

    // Update the previous value ref to the current value for the next effect run
    prevFieldValueRef.current = field.value;
  }, [field.value]);



  const onDeleted = (e: any) => {
    const { layers } = e;
    const circlesGroup = featureGroupRef.current as any;

    const circles:any = []
    for(let key in circlesGroup._layers) {
      const layer = circlesGroup._layers[key];
      const circle = {
        id: uuidv4(),
        lat: layer._latlng.lat,
        lng: layer._latlng.lng,
        radius: parseInt(layer._mRadius),
      }
      circles.push(circle)
    }
    

    setExistingCircles(circles)
    // setExistingCircles((prevCircles: any) => prevCircles.filter((circle: any) => !forDelete.includes(circle.id)));
  }


  // Initialize refs for alarmType and notificationMethod
  const alarmTypeRef = useRef(alarmType);

  // Sync the current state with refs
  useEffect(() => {
    alarmTypeRef.current = alarmType;
  }, [alarmType]);

  const onCreate = (e: any) => {
    helpers.setTouched(true);
    const { layer } = e;

    const circle = {
      id: uuidv4(),
      lat: layer._latlng.lat,
      lng: layer._latlng.lng,
      radius: parseInt(layer._mRadius),
      alarm: alarmTypeRef.current
    }

    layer.options.id = circle.id;

    setNewCircle({ circle, layer });
  };

  useEffect(() => {
    if (newCircle) {
      const { circle, layer } = newCircle;
      setExistingCircles((prevCircles: any) => {
        let overlapsWithExisting = false;

        for (let existingCircle of prevCircles) {
          const centerDistance = distance(
            [existingCircle.lat, existingCircle.lng],
            [circle.lat, circle.lng],
            { units: "meters" }
          );

          const combinedRadii = existingCircle.radius + circle.radius;

          if (centerDistance < combinedRadii) {
            overlapsWithExisting = true;
            console.log(
              "Overlap detected with circle ID:",
              existingCircle.id,
              "Center Distance:",
              centerDistance,
              "Combined Radii:",
              combinedRadii
            );
            break; // If we find one overlap, we don't need to check further
          }
        }
        setNewCircle(null);

        if (!overlapsWithExisting) {
          return [...prevCircles, circle];
        } else {
          const el = featureGroupRef.current as any;
          if (el) el.removeLayer(layer);
          return prevCircles;
        }
      })
    }
  }, [newCircle])

  useEffect(() => {
    const stringifiedCircles = JSON.stringify(existingCircles);
    if (isDifferent(stringifiedCircles, prevExistingCirclesRef.current)) {
      if (isDifferent(stringifiedCircles, field.value)) {
        helpers.setValue(stringifiedCircles);
      }
    }
    prevExistingCirclesRef.current = stringifiedCircles;
  }, [existingCircles, helpers, field.value]);

  const { t } = useTranslation();


  return (
    <React.Fragment>
      <div className={`form-group form-group-default ${meta.touched && meta.error ? 'has-error' : ''}`}>
        <input
          {...field}
          {...props}
          hidden
        />
        <div className="btn-group btn-group-toggle m-b-5 ">
          <button className={alarmType == 'both' ? 'btn btn-primary active' : 'btn btn-primary'} onClick={(e) => { e.preventDefault(); setAlarmType('both'); }}>{t('direction.both')}</button>
          <button className={alarmType == 'IN' ? 'btn btn-success active' : 'btn btn-success'} onClick={(e) => { e.preventDefault(); setAlarmType('in'); }}>{t('direction.in')}</button>
          <button className={alarmType == 'OUT' ? 'btn btn-danger active' : 'btn btn-danger'} onClick={(e) => { e.preventDefault(); setAlarmType('out'); }}>{t('direction.out')}</button>
        </div>

        <MapContainer style={{ height: "500px", width: "100%" }} center={center} zoom={12} scrollWheelZoom={true}>
          <TileLayer url="https://naramky.neurosmart.cz/osm_tiles/{z}/{x}/{y}.png" />
          <ChangeView circles={existingCircles} />
          <FeatureGroup ref={featureGroupRef}>
            {existingCircles.map((circle: any) => {
              return (
                <Circle
                  key={circle.id}
                  center={[circle.lat, circle.lng]}
                  radius={circle.radius}
                  pathOptions={{ color: getCircleColorByAlarmType(circle.alarm) }}
                />
              )
            })}
            <EditControl
              position='topright'
              draw={{
                circle: {
                  shapeOptions: {
                    color: circleColor,
                    fillColor: circleColor,
                  }
                },
                rectangle: false,
                circlemarker: false,
                polygon: false,
                marker: false,
                polyline: false,
              }}
              edit={{
                edit: false
              }}
              onCreated={onCreate}
              onDeleted={onDeleted}
            />
          </FeatureGroup>
        </MapContainer>
      </div>
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </React.Fragment>
  );
};

export default InputMap;