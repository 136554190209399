import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import Input from "../../components/elements/Input";
import { useTranslation } from "react-i18next";
import { loginApiClient } from "../../api/calls";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('resetPassword.error.invalidEmail')
    .required('resetPassword.error.emailRequired'),
});

const ForgotPasswordForm: React.FC<{toggleLoginForm: Function}> = ({toggleLoginForm}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={async (values, actions) => {
        try {
          await loginApiClient.post('/noauth/resetPasswordByEmail', values, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
         toggleLoginForm(false)
        } catch (error) {
          console.error(error);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Input
            label="login.resetPassword.email"
            name="email"
            type="email"
            className="form-control"
            required
          />
          <button
            type="submit"
            className="btn btn-complete btn-lg m-t-10"
            disabled={!isValid || isSubmitting}
          >
            {t("login.resetPassword.button")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;