import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import ReactECharts from "echarts-for-react";
import Select from "./Select";
import { useTranslation } from "react-i18next"

interface PatientTelemetry {
  heartRate?: any;
  temperature?: any;
  calories?: any;
  calories_delta?: any;
  latitude?: any;
  longitude?: any;
  battery?: any;
  batteryPercentage?: any;
  batteryVoltage?: any;
  distance?: any;
  distance_delta?: any;
  steps?: any;
  steps_delta?: any;
  ts: number;
}

type ChartProps = {
  data: PatientTelemetry[];
  deviceType: string;
};

const AutoSubmit = () => {
  const { values, submitForm } = useFormikContext<{start: string, end: string}>();
  useEffect(() => {
    submitForm();
  }, [values, submitForm]);
  return null;
};


const DEVICE: any = {
  "LW-360HR": [
    "heartRate",
    "temperature",
    "battery",
    "distance",
    "steps",
    "calories",
  ],
  "QL-GL320M": [
    "batteryPercentage",
    "temperature",
  ],
  "MT710": [
    "batteryVoltage",
  ]
}

  
const Chart: React.FC<ChartProps> = ({ data, deviceType}) => {
  const [option, setOption] = useState({});
  const [selectedTelemetry, setSelectedTelemetry] = useState<keyof PatientTelemetry>("heartRate");

  useEffect(()=>{
    console.log(deviceType)
    if(deviceType === 'LW-360HR') {
      setSelectedTelemetry('heartRate')
    } else if(deviceType === 'QL-GL320M') {
      setSelectedTelemetry('batteryPercentage')
    } else if(deviceType === 'MT710') {
      setSelectedTelemetry('batteryVoltage')
    }

  }, [deviceType])

  const {t} = useTranslation()

  let telemetryFields: any[] = DEVICE[deviceType];

  telemetryFields = telemetryFields.map(field=>({ id: field, label: t("telemetry."+field) }));


  const currentTelemetry = (id:string)=> telemetryFields.filter((item) => item.id === id);
  
  useEffect(() => {
    const processedData = processChartData(data, selectedTelemetry, deviceType);

    setOption({
      tooltip: {
        trigger: "axis",
        formatter: function (params:any) {
          const date = new Date(params[0].axisValue);
          const options:any = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
          const formattedDate = new Intl.DateTimeFormat('cs-CZ', options).format(date);
          return `${formattedDate}: Hodnota: ${params[0].data}`;
        },
        backgroundColor: "#fff",
        padding: 10,
        textStyle: {
          color: "black",
          fontSize: 13,
          fontFamily: "Arial",
        },
        axisPointer: {
          type: "line",
          lineStyle: {
            opacity: 0.6,
          },
        },
        extraCssText: "box-shadow: 0 0 6px rgba(0,0,0,0.1);",
      },
      grid: {
        left: "1%",
        right: "1%",
        top: "70px",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        show: true,
        data: processedData.xData,
        axisLabel: {
          formatter: (value: string) => {
            const date = new Date(value);
            const format:any = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            return new Intl.DateTimeFormat('cs-CZ', format).format(date);
          }
        }
      },
      yAxis: {
        type: "value",
      },
      series: [{
        name: t('telemetry.'+selectedTelemetry),
        data: processedData.yData,
        type: 'line' // or any other type you prefer
      }],
    });
  }, [t, data, selectedTelemetry, deviceType]);

  const processChartData = (data: PatientTelemetry[], key: keyof PatientTelemetry, deviceType: string) => {
    let xData: string[] = [];
    let yData: (number | string)[] = [];


    for( const item of data) {
      const value = item[key];
      if(!value) continue

      const date  = new Date(item.ts);
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

      xData.push(formattedDate);
      yData.push(value);
    }

    return { xData, yData };
  };

  return (
    <>
      <Formik
        initialValues={{ telemetry: "heartRate" }}
        onSubmit={(values: any) => {
          if(!values.telemetry) return;
          setSelectedTelemetry(values.telemetry);
        }}
      >
        <Form>
          <div className="telemetry-dropdown">
            <Select
              name="telemetry"
              label={t("telemetry.dropdown_label")}
              options={telemetryFields}
              valueKey="id"
              labelKey="label"
              blurOut={true}
              selected={currentTelemetry(selectedTelemetry|| 'heartRate')}
            />
          </div>
          <AutoSubmit />
        </Form>
      </Formik>
      <ReactECharts option={option} />
    </>
  );
};

export default Chart;
