import { useContext, useEffect, useState } from "react"
import Table from "../../components/elements/Table"
import Modal from '../../components/elements/Modal';
import PageLayout from "../../components/layout/Page"
import { apiClient } from "../../api/calls"
import { useTranslation } from "react-i18next"
import { useAlarmsWithTime, useRefetchAlarms } from "../../api/hooks";
import Quickbar from "../../components/layout/Quickbar";
import useAlarmsTableColumns from "./AlarmsTableColumns";
import AlarmDetail from "./AlarmDetail";
import AlarmFilter from "./AlarmFilter";
import { useQueryClient } from "react-query";
import { AlertContext } from "../../context/AlertContext";



const Component = ()=> {
  const message = useContext(AlertContext);

  const [ackId, setAckId] = useState("")
  const [detailOpen, setDetailOpen] = useState(false);

  let end = new Date()
  end.setMinutes(end.getMinutes() + 5);
  let start = new Date()
  start.setDate(start.getDate() - 30)
  
  const [timeFilter, setTimeFilter] = useState<{start: Date, end: Date}>({start, end})


  const [alarmId, setAlarmId] = useState("")

  const closeQuickview = ()=>{
    setDetailOpen(false)
  }

  const handleViewDetail = (id: string)=>{
    setDetailOpen(true)
    setAlarmId(id)
  }

  const { t } = useTranslation();
  const { isLoading, data, refetch } = useAlarmsWithTime(100, "ANY", timeFilter.start.valueOf(), timeFilter.end.valueOf())

  useEffect(()=>{
    let end = new Date()
    let start = new Date()

    end.setMinutes(end.getMinutes() + 5);
    start.setDate(start.getDate() - 30)
    setTimeFilter({start, end})

    if(typeof refetch === "function") {
      refetch()
    }

  }, [])

  useEffect(()=>{
    if(message) {
      if(typeof refetch === "function") {
        refetch()
      }
    }
  }, [message])


  const queryClient = useQueryClient();
  const {mutateAsync} = useRefetchAlarms()
  const [slideUpVisible, setSlideUpVisible] = useState(false);

  const AckAlarm = async (localAckId?: string )=> {
    const id = localAckId || ackId

    if(!id) return
    try {
      await apiClient.request({ url: `/alarm/${id}/ack`, method: 'POST' })
      await apiClient.request({ url: `/alarm/${id}/clear`, method: 'POST' })
    } catch (error) {
      console.error(""+error)
    } finally {
      if(!localAckId) {
        setAckId("")
      }
      await refetch()
      setDetailOpen(false)
      setSlideUpVisible(false)
    }
  }

  useEffect(() => {
    const refetchAlarms = async () => {
      if(timeFilter.start && timeFilter.end) {
        try {
          const res = await mutateAsync({
            pageSize: 100,
            searchStatus: "ANY",
            start: timeFilter.start.valueOf(),
            end: timeFilter.end.valueOf(),
          });
          queryClient.setQueryData(['alarms_ANY_100'], res);
        } catch (error) {
          console.error(error);
        }
      }
    };
    refetchAlarms();

  }, [timeFilter, mutateAsync, queryClient])

  const columns = useAlarmsTableColumns(
    setSlideUpVisible,
    setAckId,
    handleViewDetail,
  )

  return (
    <PageLayout>
      <div className="container-fluid container-fixed-lg bg-white">
        <div className="card card-transparent m-t-50">
          <div className="card-header ">
            <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between">
              <div className="card-title fs-16 p-b-10 bold">{t('alarms.tableTitle')}</div>
              <AlarmFilter
              start={start}
              end={end} 
              setTimeFilter={setTimeFilter}/>
            </div>
          </div>
          <div className="card-body">
          <Table 
              withIndex={true}
              isLoading={isLoading}
              columns={columns} 
              data={data?.data} 
              defaultSorting={[{ id: 'createdTime', desc: true }]}
              columnVisibility={{id: false, ts: false, status: false, device_id: false, details: false}}
              className="shrink sticky" />
          </div>
        </div>
      </div>
      <Quickbar
        isOpen={detailOpen}
        onRequestClose={closeQuickview}
        width={40}
      >
         <AlarmDetail isOpen={detailOpen} Ack={AckAlarm} alarmId={alarmId}/>
      </Quickbar>
      <Modal 
        visible={slideUpVisible}
        width={"600"}
        effect="fadeInUp"
        onClickAway={() => setSlideUpVisible(false)}
        >
          <div className="modal-content-wrapper">
              <div className="modal-content">
                <div className="modal-top">
                  <button
                    aria-label=""
                    type="button"
                    className="close"
                    onClick={() => setSlideUpVisible(false)}
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <i className="pg-icon">close</i>
                  </button>
                  <h5>
                     <span className="semi-bold">{t('alarms.modal.title')}</span>
                  </h5>
                 
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 text-center p-3">
                      <p>{t('alarms.modal.message')}</p>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <button
                          aria-label=""
                          type="button"
                          className="btn btn-danger btn-block m-t-5"
                          onClick={() => setSlideUpVisible(false)}
                        >
                         {t('alarms.modal.cancel')}
                        </button>
                          
                      </div>
                    <div className="col-md-6">
                      <button
                        aria-label=""
                        type="button"
                        onClick={() => AckAlarm()}
                        className="btn btn-success btn-block m-t-5"
                      >
                       {t('alarms.modal.confirm')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </Modal>
    </PageLayout>
  )
}

export default Component

