import { createRefresh } from 'react-auth-kit'
import { apiClient } from '../api/calls'

const refreshApi = createRefresh({
  interval: 1,   // Refreshs the token in every 10 minutes
  refreshApiCallback: async (params) => {
      try {
        const response:any = await apiClient.post(
          '/auth/token',
          {
            refreshToken: params.refreshToken,
          },
          {
            headers: {
              'Authorization': `Bearer ${params.authToken}`
            },
          }
        )
       
        // hack needed due to poor TS implementation of refreshApiCallback
        const tokenResponse: any = {
          isSuccess: true,
          newAuthToken: response.data.token,
          newRefreshToken: response.data.refreshToken,
        }
        return tokenResponse
      } catch (error) {
        const tokenResponse: any = {
          isSuccess: false,
          authUserState: null
        }
        return  tokenResponse
      }
   
  }
})

export default refreshApi