import React from "react"
import Footer from "./Footer"

const PageLayout = (props:any)=> {
  const className = props.className ? props.className : ''
  return (
    <React.Fragment>
      <div className={`content ${className} vh-100`}>
        {props.children}
       
      </div>
      <Footer/>
    </React.Fragment>
  )
}

export default PageLayout