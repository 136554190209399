import { useTranslation } from "react-i18next";
import Quickbar from "../../components/layout/Quickbar";
import { Form, Formik } from "formik";
import Input from "../../components/elements/Input";
import Select from "../../components/elements/Select";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCaretaker, usePatientsWithDevices } from "../../api/hooks";
import { assignPatientToCaretaker, unassignPatientFromCaretaker } from "../../api/calls";
import Table from "../../components/elements/Table";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { UserRoleContext } from "../../providers/UserRoleProvider";

const CaretakersForm:React.FC<{
  isOpen: boolean,
  caretakerId: any,
  onRequestClose: Function, 
}> = ({isOpen, caretakerId, onRequestClose})=>{
  const { userRole } = useContext(UserRoleContext);
  const [internalOpen, setInternalOpen] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({})
  const [filteredList, setFilteredList] = useState<any>([])
  const [dropdownOptions, setDropdownOptions] = useState<any>([])
  const caretaker = useCaretaker(caretakerId)
  const { t } = useTranslation()

  const patients = usePatientsWithDevices(userRole)
  useEffect(() => {
    if(!patients.data || !caretaker.data) return
    const filtered = []

    const patientIds = patients.data.map((item:any)=> item.id)
    for(let item of caretaker.data.patientAssets) {
      if(patientIds.includes(item.id.id)) {
        const patient = patients.data.find((patient:any)=> patient.id === item.id.id)
        if(!patient) continue
        filtered.push(patient)
      }
    }
    const dropdownOptions = []
    for(let item of patients.data) {
      if(!caretaker.data.patientAssets.find((asset:any)=> asset.id.id === item.id)) {
        dropdownOptions.push(item)
      }
    }

    setDropdownOptions(dropdownOptions.sort((a:any, b:any) => a.name.localeCompare(b.name)))

    setInitialValues({
      caretaker: caretaker.data.name,
      patientId: ""
    })

    setFilteredList(filtered.sort((a:any, b:any) => a.name.localeCompare(b.name)))

  }, [caretaker.data, patients.data, setInitialValues, setFilteredList, setDropdownOptions])

  useEffect(() => {
    setInternalOpen(isOpen)
  }, [isOpen, setInternalOpen])

  const handleUnpair = useCallback(async (id: any) => {
    if(!caretaker.data) return
    await unassignPatientFromCaretaker(id, caretaker.data.customerId.id)
    await caretaker.refetch()
  }, [caretaker])

  const columns = useMemo(()=> [
    {
      id: 'id',
      accessorFn: (row: any)=>{
        return row.id
      }
    },
    {
      id: 'name',
      header: t("caretakers.pair.table.name"),
      accessorFn: (row: any)=>{
        return row.name
      }
    },
    {
      id: 'ambulance',
      header: t("caretakers.pair.table.ambulance"),
      accessorFn: (row: any)=>{
        return row.ambulance || '-'
      }
    },
    {
      id: 'actions',
      header: t("caretakers.table.actions"),
      className: 'shrink',
      cell:  (info:any) => {
        const id = info.row.getValue('id')
        return (
          <div className='table-actions'>
            <span data-tooltip-id={"tooltip-edit"+id} >
              <button
                className='btn btn-danger'
                onClick={()=>{
                  handleUnpair(id)
                }}
              >
                <Icon>trash_alt</Icon>
              </button>
            </span>
            <Tooltip id={"tooltip-edit"+id} place="top">
              {t('caretakers.action.delete')}
            </Tooltip>
          </div>

        )
      }
    }
  ], [t, patients.data, handleUnpair])

  return (
    <Quickbar
      isOpen={internalOpen}
      onRequestClose={onRequestClose}
      width={40}
    >
   <div className="card card-transparent m-t-50">
      <div className="card-header">
        <div className="card-title">{t('caretakers.form.title')}</div>
      </div>
      <div className="card-body">
        <Table
          withIndex={true}
          isLoading={caretaker.isLoading}
          columns={columns}
          data={filteredList || []}
          columnVisibility={{ id: false }}
          defaultSorting={[{ id: 'name', desc: true }] }
          className="shrink sticky"
        />
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              try {
                await assignPatientToCaretaker(values.patientId, caretaker.data.customerId.id )
                setInitialValues({
                  caretaker: caretaker.data.name,
                  patientId: ""
                })
                await caretaker.refetch()
              } catch (error) {
                console.error(error)
              } finally {
                actions.setSubmitting(false)
              }
            }}
          >
           {({ isSubmitting, isValid}) => (
            <Form>
              <br/>
              <p>{t('caretakers.pair.description')}</p>
              <br/>
              <div className="form-group-attached">
                <div className="row clearfix">
                  <div className="col-md-6">
                    <Input
                      className="form-control"
                      label={t("caretakers.pair.form.caretaker")}
                      type="text"
                      name="caretaker"
                      readonly />
                  </div>
                  <div className="col-md-6">
                    <Select
                      name="patientId"
                      label={t("caretakers.pair.form.patient")}
                      options={dropdownOptions}
                      valueKey={'id'}
                      labelKey={'name'}
                      required />
                  </div>
                </div>
              </div>
              <br/>
              <button
                aria-label=""
                className="btn btn-success pull-right"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t('caretakers.pair.save')}
              </button>
              <button
                aria-label=""
                className="btn btn-primary pull-left"
                type="button"
                disabled={isSubmitting}
                onClick={()=>{
                  onRequestClose()
                }}
              >
                {t('caretakers.pair.done')}
              </button>
            </Form>
             )}
          </Formik>
        </div>
    </div>
  </Quickbar>
  )
}

export default CaretakersForm;