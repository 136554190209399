// columnsDefinition.ts
import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { useMemo } from "react";
import { Alarm } from "../../types"


const useAlarmsTableColumns = (
  setSlideUpVisible: (bool: boolean) => void,
  setAckId: (id: string) => void,
  handleViewDetail: (id: string) => void
) => {

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'id',
        accessorFn: (row: Alarm)=>{
          return row.id.id
        }
      },
      {
        id: 'ts',
        accessorFn: (row: Alarm)=>{
          return row.createdTime
        }
      },
      {
        id: 'device_id',
        accessorFn: (row: Alarm)=>{
          return row.originator.id
        }
      },
      {
        id: 'details',
        accessorFn: (row: Alarm)=>{
          return row.details
        }
      },
      {
        id: 'status',
        accessorFn: (row: Alarm)=>{
          return row.status
        }
      },
      {
        header: t('alarms.table.severity'),
        id: 'severity',
        accessorFn: (row: Alarm)=>{
          return t('alarms.severity.'+row.severity)
        }
      },
      {
        header: t('alarms.table.status'),
        id: 'displayStatus',
        accessorFn: (row: Alarm)=>{
          return  t('alarms.status.'+row.status)
        }
      },
      {
        header: t('alarms.table.type'),
        id: 'type',
        accessorFn: (row: Alarm)=>{
          return t('alarms.type.'+row.type)
        }
      },
      {
        header: t('alarms.table.patientName'),
        id: 'originatorName',
        accessorFn: (row: Alarm)=>{
          return row.details.sys_pacientName || "-"
        }
      },
      {
        header: t('alarms.table.createdTime'),
        id: 'createdTime',
        accessorFn: (row: Alarm)=>{
          return new Date(row.createdTime).toLocaleDateString('cs-CZ', { hour: 'numeric', minute: 'numeric' })
        }
      },
      {
        id: 'actions',
        header: t('alarms.table.actions'),
        className: 'shrink',
        cell: (info:any) => {
          const id = info.row.getValue('id')
          const ts = info.row.getValue('ts')
          const device_id = info.row.getValue('device_id')
          const status = info.row.getValue('status')
          return (
          <div className="table-actions">
            <span data-tooltip-id={"tooltip-view"+id}>
              <button 
                className='btn btn-primary' 
                onClick={()=>{
                  handleViewDetail(id)
                }}
              >
                <Icon>eye</Icon>
              </button>
            </span>
            <Tooltip className="tooltip-visible" id={"tooltip-view"+id} place="top">{t('alarms.action.view')}</Tooltip>

            <span data-tooltip-id={"tooltip-ack"+id}>
              <button 
                className='btn btn-success' 
                type="button"
                onClick={()=>{
                  setSlideUpVisible(true)
                  setAckId(id)
                }}
                disabled={!!status.endsWith('_ACK')}>
                <Icon>tick</Icon>
              </button>
            </span>
            <Tooltip className="tooltip-visible" id={"tooltip-ack"+id} place="top">{t('alarms.action.ack')}</Tooltip>
          </div>

          )
        }
      }
     
    ],
    []
  )

  return columns;

}

export default useAlarmsTableColumns