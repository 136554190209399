import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDevices, usePatients } from "../../api/hooks"
import Table from "../../components/elements/Table"
import PageLayout from "../../components/layout/Page"
import useDeviceTableColumns from "./DevicesTableColumns"
import DevicePair from "./DevicePair"
import Modal from "../../components/elements/Modal"
import { deleteAlert, getAlarmsRelatedFromPatient, removeAssetDeviceRelation } from "../../api/calls"

const Component = () => {
  const devices = useDevices()
  const patients = usePatients()
  const [modalVisible, setModalVisible] = useState(false)
  const [device, setDevice] = useState<any>(null)
  const [combined, setCombined] = useState<any>([])
  const [patientList, setPatientList] = useState<any>([])
  const [unlinkOptions , setUnlinkOptions] = useState({
    deviceId: "",
    patientId: "",
    refetch: async ()=>{
      await devices.refetch()
      await patients.refetch()
    }
  })
  const [pairOpen, setPairOpen] = useState(false)
  

  const doUnlink = async ()=> {
    const {deviceId, patientId, refetch} = unlinkOptions
    
    try {
      await removeAssetDeviceRelation(patientId, deviceId)
      
      // delete all alarms related to this device
      const alarms = await getAlarmsRelatedFromPatient(patientId)
      for(const alarmId of alarms) {
        await deleteAlert(alarmId)
      }
    } catch (error) {
      console.error(error)
    } finally {
      await refetch()
      setModalVisible(false)
    }
  }

  const handleUnpair = (id: any) => {
    const device = combined.find((item: any) => item.id === id)
    if(!device) return
    setUnlinkOptions({
      deviceId: device.id,
      patientId: device.sys_assignment_assetID,
      refetch: async ()=>{
        await devices.refetch()
        await patients.refetch()
      }
    })
    setModalVisible(true)
  }

  const openPairForm = (id: string) => {
    const device = combined.find((item: any) => item.id === id)
    if(!device) return
    setDevice(device)
    setPairOpen(true)
  }

  useEffect(() => {
    if (!devices.data || !patients.data) return
    const temp = []
    const pl = []
    for (let device of devices.data) {
      const patient = patients.data.find((patient) => patient.id === device.sys_assignment_assetID)
      const obj = { ...device, assignedTo: patient ? patient.name : '-' }
      temp.push(obj)
    }
    setCombined(temp)

    for (let patient of patients.data) {
      const obj = { id: patient.id, label: patient.name, device: patient.sys_assignment_devID  }
      pl.push(obj)
    }

    setPatientList(pl)

  }, [devices.data, patients.data])

  const { t } = useTranslation();

  const columns = useDeviceTableColumns(
    openPairForm,
    handleUnpair,
  )

    // refetch on mount
    useEffect(() => {
      if(typeof devices.refetch === 'function') {
        devices.refetch();

      }
      if(typeof patients.refetch === 'function') {
        patients.refetch();
      }
    }, []);


    useEffect(()=> {
      if(typeof devices.refetch === 'function') {
        devices.refetch();

      }
      if(typeof patients.refetch === 'function') {
        patients.refetch();
      }
    }, [pairOpen])

  return (
    <PageLayout>
      <div className="container-fluid container-fixed-lg bg-white">
        <div className="card card-transparent m-t-50">
          <div className="card-header ">
            <div className="card-title fs-16 p-b-10 bold">{t('devices.tableTitle')}</div>
          </div>
          <div className="card-body ">
            <Table
              withIndex={true}
              isLoading={combined.length === 0}
              columns={columns}
              data={combined}
              columnVisibility={{ id: false }}
              className="sticky"
            />
          </div>
        </div>
      </div>
      <Modal 
        visible={modalVisible}
        width={"600"}
        effect="fadeInUp"
        onClickAway={() => setModalVisible(false)}
        >
          <div className="modal-content-wrapper">
              <div className="modal-content">
                <div className="modal-top">
                  <button
                    aria-label=""
                    type="button"
                    className="close"
                    onClick={() => setModalVisible(false)}
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <i className="pg-icon">close</i>
                  </button>
                  <h5>
                     <span className="semi-bold">{t('patients.unlink.title')}</span>
                  </h5>
                 
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 text-center p-3">
                      <p>{t('patients.unlink.message')}</p>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <button
                          aria-label=""
                          type="button"
                          className="btn btn-block m-t-5"
                          onClick={() => setModalVisible(false)}
                        >
                         {t('patients.unlink.cancel')}
                        </button>
                          
                      </div>
                    <div className="col-md-6">
                      <button
                        aria-label=""
                        type="button"
                        onClick={() => doUnlink()}
                        className="btn btn-danger btn-block m-t-5"
                      >
                       {t('patients.unlink.confirm')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </Modal>
      <DevicePair
        device={device}
        isOpen={pairOpen}
        list={patientList}
        onRequestClose={async () => {
          setPairOpen(false)
          await devices.refetch()
          await patients.refetch()
        }}
      />
    </PageLayout>
  )
}

export default Component

