import { LatLngExpression } from "leaflet"
import { useContext, useEffect, useState } from "react"
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { useDevicePositions } from "../../api/hooks"
import { Link } from "react-router-dom"
import { UserRole, UserRoleContext } from "../../providers/UserRoleProvider"

const DEFAULT_MAP_POSITION: LatLngExpression = [49.195061, 16.606836]
const DEFAULT_MAP_ZOOM = 11

const ChangeView = ({ bounds }: { bounds: any }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [bounds, map]);
  return null;
};

const DeviceMap = () => {
  const {data:positions} = useDevicePositions()
  const { userRole } = useContext(UserRoleContext);
  const [bounds, setBounds] = useState<any>([])


  useEffect(() => {
    if(!positions) return
    const bounds = positions.reduce<LatLngExpression[]>((boundsArray, pos:any) => {
      const currentPos: LatLngExpression = [parseFloat(pos.lat), parseFloat(pos.long)];
      if (currentPos[0] !== 0 || currentPos[1] !== 0) {
        boundsArray.push(currentPos);
      }
      return boundsArray;
    }, []);
    setBounds(bounds)
  }, [positions])

  return (
    <MapContainer
      style={{ height: "500px", width: "100%" }}
      center={DEFAULT_MAP_POSITION}
      zoom={DEFAULT_MAP_ZOOM}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://naramky.neurosmart.cz/osm_tiles/{z}/{x}/{y}.png" />
      <FeatureGroup >
        {positions?.map(position => {
          if(userRole === UserRole.Caretaker && !position.assetId) return null
          return (
            <Marker position={[position.lat, position.long]} key={position.id}>
              <Popup>
                <Link to={`/patients/${position.assetId}`}>
                  {position.assetName} <br />
                  {position.label} <br />
                </Link>
              </Popup>
            </Marker>
          )
        })}
      </FeatureGroup>
      <ChangeView bounds={bounds}/>
    </MapContainer>

  )
}

export default DeviceMap