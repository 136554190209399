import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from '../types';
import cz from './cz.json';
import en from './en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    cz: {
      translation: cz
    },
  },
  lng: Languages.Cz,
  fallbackLng: Languages.En,
  returnNull: false,
});

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

export default i18n;
