import { Children, ReactElement, useEffect, useRef, useState } from "react"
import Icon from '../elements/Icon'
import {isMobile} from 'react-device-detect';

const Quickbar: React.FC<{
  children: ReactElement
  isOpen: boolean,
  width?: number,
  onRequestClose: Function
}> = ({isOpen, onRequestClose, width, children, ...rest})=> {
  const [internalOpen, setInternalOpen] = useState(false)
  const ref = useRef(null);

  useEffect(() => {
    const clicked = (e: any) => {
      if (!isOpen) return
      if (!internalOpen) {
        return setInternalOpen(true)
      }
      if (ref.current) {
        //@ts-ignore
        const { x } = ref.current.getBoundingClientRect()
        if (x > e.clientX) {
          internalClose()
        }
      }
    }
    window.addEventListener('click', clicked)

    // return a clean-up function
    return () => {
      window.removeEventListener('click', clicked)
    }
  })

  const internalClose = () => {
    onRequestClose()
    setInternalOpen(false)
  }

  const quickviewStyle = {
    right: width ? `${width * -1}%` : "-70%",
    width: width ? `${width}%` : "70%",
  }

  return (
    <div 
      className={`quickview-wrapper builder ${isOpen ? "open" : ""}`} 
      style={!isMobile? quickviewStyle : {right: "-100%", width: "100%"}}
      ref={ref}
    >
      <Icon onClick={internalClose} className="quickview-close">close</Icon>
      <div className={`${!isMobile ? 'p-l-30 p-r-30' : ''} `}>
          {children}
      </div>
    </div>
  )
}

export default Quickbar