// columnsDefinition.ts
import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { useMemo } from "react";
import { Device } from "../../types"


const useDeviceTableColumns = (
  openPairForm: (id: string) => void,
  handleUnpair: (id: string) => void,
) => {

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'id',
        accessorFn: (row: Device) => {
          return row.id
        }
      },
      {
        id: 'name',
        header: t('devices.table.name'),
        accessorKey: 'name'
      },
      {
        id: 'label',
        header: t('devices.table.label'),
        accessorKey: 'label',
        accessorFn: (row: Device) => {
          return row.label || '-'
        }
      },
      {
        id: 'type',
        header: t('devices.table.type'),
        accessorKey: 'type'
      },
      {
        id: 'assignedTo',
        header: t('devices.table.assignedTo'),
        accessorKey: 'assignedTo',
        accessorFn: (row: any) => {
          return row.assignedTo || '-'
        }
      },
      {
        header: t('devices.table.latitude'),
        id: 'latitude',
        accessorKey: 'latitude',
        accessorFn: (row: Device) => {
          return row.latitude || '-'
        }
      },
      {
        header: t('devices.table.longitude'),
        id: 'longitude',
        accessorKey: 'longitude',
        accessorFn: (row: Device) => {
          return row.longitude || '-'
        }
      },
      {
        header: t('devices.table.timestamp'),
        id: 'timestamp',
        accessorFn: (row: Device) => {
          return row.ts ? new Date(row.ts).toLocaleString('cs-CZ', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}) : "-"
        }
      },
      {
        id: 'actions',
        header: t('alarms.table.actions'),
        className: 'shrink',
        cell: (info: any) => {
          const id = info.row.getValue('id')
          const assignedTo = info.row.getValue('assignedTo')
          const isPaired = assignedTo !== '-'
          return (
            <div className="table-actions">
              {isPaired ? (
                <>
                  <span data-tooltip-id={"tooltip-unpair" + id}>
                    <button
                      className='btn btn-danger'
                      type="button"
                      onClick={() => {
                        handleUnpair(id)
                      }}
                    >
                      <Icon>trash_alt</Icon>
                    </button>
                  </span>
                  <Tooltip className="tooltip-visible" id={"tooltip-unpair" + id} place="top">{t('devices.action.unpair')}</Tooltip>
                </>
              ) : (
                <>
                  <span data-tooltip-id={"tooltip-pair" + id}>
                    <button
                      className={`btn btn-success`}
                      type="button"
                      onClick={() => {
                        openPairForm(id)
                      }}
                    >
                      <Icon>{'link'}</Icon>
                    </button>
                  </span>
                  <Tooltip className="tooltip-visible" id={"tooltip-pair" + id} place="top">{t('devices.action.pair')}</Tooltip>
                </>
              )}
            </div>
          )
        }
      }
    ],
    [t, openPairForm, handleUnpair]
  )

  return columns;

}

export default useDeviceTableColumns