import axios from 'axios';
import jwtDecode from "jwt-decode";
import { uniqueId } from 'react-bootstrap-typeahead/types/utils';
import config from '../config/config';
import { Alarm, Device, DeviceResponse, Patient, PatientResponse, PatientTelemetry, Positions, TelemetryValues, ThingsboardResponse, UserProfile, UserProfileMutation } from '../types/thingsboard';

const ASSET_PROFILE: any = {
  "alertDefinition-telemetry": "95359860-20ae-11ee-9f53-61216da9c7cb",
  "alertDefinition-geo": "87ad6470-20ae-11ee-9f53-61216da9c7cb",
  "alertDefinition-emergency": "a1c27c60-20ae-11ee-9f53-61216da9c7cb"
}


export const loginApiClient = axios.create({
  baseURL: config.apiURL
})

export const apiClient = axios.create({
  baseURL: config.apiURL,
  withCredentials: true,
});

export const protectedURL = axios.create({
  baseURL: config.protectedURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'  // Explicitly set Content-Type
  }
})

protectedURL.interceptors.request.use(config => {
  const auth = localStorage.getItem('_auth')
  const authType = localStorage.getItem('_auth_type')
  if (auth && authType) {
    config.headers!.Authorization = `${authType} ${auth}`
  }
  return config
})


apiClient.interceptors.request.use(config => {
  const auth = localStorage.getItem('_auth')
  const authType = localStorage.getItem('_auth_type')
  if (auth && authType) {
    config.headers!.Authorization = `${authType} ${auth}`
  }
  return config
})

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // If there's an error response from the server
    if (error.response) {
      // Check if the error is a 401
      if (error.response.status === 401) {
        logoutUser();
        return;
      }
    }

    // Check if the token in local storage has expired
    const auth = localStorage.getItem('_auth');
    if (auth) {
      const decodedToken: any = jwtDecode(auth);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        logoutUser();
        return;
      }
    }

    return Promise.reject(error);
  }
);

const logoutUser = () => {
  // Clean up the auth from local storage or whatever logic you use to logout
  localStorage.removeItem('_auth');
  localStorage.removeItem('_auth_type');
  // Depending on your setup, you might also want to redirect the user to the login page.
};

const pacientFilter = {
  key: {
    type: "ENTITY_FIELD",
    key: "type"
  },
  valueType: "BOOLEAN",
  predicate: {
    operation: "EQUAL",
    value: {
      defaultValue: 'pacient',
      dynamicValue: null
    },
    type: "STRING"
  }
}

const pageLink = {
  page: 0,
  pageSize: 100
}

export const fetchPatients = async (): Promise<Patient[]> => {
  const query: any = {
    entityFilter: {
      type: "entityType",
      entityType: "ASSET"
    },
    entityFields: [
      {
        type: "ENTITY_FIELD",
        key: "name"
      },
      {
        type: "ENTITY_FIELD",
        key: "label"
      }
    ],
    latestValues: [
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_devID"
      },
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_status"
      }
    ],
    keyFilters: [
      pacientFilter
    ],
    pageLink
  }

  const { data } = await apiClient.post('/entitiesQuery/find', query)

  const normalized: Patient[] = data.data.map((patient: PatientResponse): Patient => {
    return {
      id: patient.entityId.id,
      name: patient.latest.ENTITY_FIELD.name.value,
      label: patient.latest.ENTITY_FIELD.label.value,
      sys_assignment_status: patient.latest.ATTRIBUTE.sys_assignment_status.value === 'true',
      sys_assignment_devID: patient.latest.ATTRIBUTE.sys_assignment_devID.value
    }
  })

  return normalized
};

export const fetchCaretakers = async (): Promise<any> => {
  const response = await protectedURL.get('/caretakers')
  return response.data
}

export const fetchDevices = async (withAssigned?: boolean): Promise<Device[]> => {
  const query: any = {
    entityFilter: {
      type: "entityType",
      entityType: "DEVICE"
    },
    entityFields: [
      {
        type: "ENTITY_FIELD",
        key: "name"
      },
      {
        type: "ENTITY_FIELD",
        key: "label"
      },
      {
        type: "ENTITY_FIELD",
        key: "type"
      }

    ],
    latestValues: [
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_assetID"
      },
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_status"
      },
      {
        type: "TIME_SERIES",
        key: "longitude"
      },
      {
        type: "TIME_SERIES",
        key: "latitude"
      }
    ],
    keyFilters: [
      {
        key: {
          type: "ENTITY_FIELD",
          key: "type"
        },
        valueType: "BOOLEAN",
        predicate: {
          type: "COMPLEX",
          operation: "OR",
          predicates: [
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'LW-360HR',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'QL-GL320M',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'MT710',
                dynamicValue: null
              },
              type: "STRING"
            }
          ]
        }
      }
    ],
    pageLink
  }

  if (withAssigned) {
    query.keyFilters = [...query.keyFilters, {
      key: {
        type: "ATTRIBUTE",
        key: "sys_assignment_status"
      },
      valueType: "BOOLEAN",
      predicate: {
        operation: "NOT_EQUAL",
        value: {
          defaultValue: true,
          dynamicValue: null
        },
        type: "BOOLEAN"
      }
    }]
  }
  const { data } = await apiClient.post('/entitiesQuery/find', query)

  const normalized: Device[] = data.data.map((device: DeviceResponse) => {
    const { latest: { ENTITY_FIELD, ATTRIBUTE, TIME_SERIES } } = device
    return {
      id: device.entityId.id,
      name: ENTITY_FIELD.name.value,
      label: ENTITY_FIELD.label.value,
      type: ENTITY_FIELD.type.value,
      sys_assignment_status: ATTRIBUTE.sys_assignment_status.value === 'true',
      sys_assignment_assetID: ATTRIBUTE.sys_assignment_assetID.value,
      latitude: parseLatLng(TIME_SERIES.latitude.value),
      longitude: parseLatLng(TIME_SERIES.longitude.value),
      ts: Math.max(TIME_SERIES.latitude.ts)
    }
  })
  return normalized
};


const parseLatLng = (value: string | null) => {
  if (!value || value === '') return null
  const parsed = parseFloat(value)
  return !!parsed ? parsed.toFixed(6) : null
}

interface DeviceObj {
  [key: string]: Device
}


export const fetchPatientsWithDevices = async (role:string): Promise<Patient[]> => {
  const {userId} = getLocalAuth()
  if(role === 'caretaker') {
    try {
      const res = await protectedURL.get(`/patients?id=${userId}`)
      return res.data
      
    } catch (error) {
      console.log(error)
      return []
    }
  }
  
  const patients = await fetchPatients()
  
  const devices = await fetchDevices()
  const devicesObj: DeviceObj = {}
  for (let device of devices) {
    devicesObj[device.id] = device
  }
  const related = await protectedURL.post(`/related`, {
    patients: patients.map((patient: Patient) => patient.id)
  })


  for (let patient of patients) {
    try {
      const res = await apiClient.post("/entitiesQuery/find", {
        entityFilter: {
          type: "relationsQuery",
          rootEntity: {
            entityType: "ASSET",
            id: patient.id,
          },
          direction: "TO",
          maxLevel: 1,
          filters: [
            {
              relationType: "Manages",
              entityType: "ASSET",
            }
          ]
        },
        entityFields: [
          {
            type: "ENTITY_FIELD",
            key: "name"
          },
          {
            type: "ENTITY_FIELD",
            key: "label"
          },
          {
            type: "ENTITY_FIELD",
            key: "additionalInfo"
          }
        ],
        pageLink
      })

      const alarms = await fetchExistinAlarmCount(patient.id)
      patient.alarms = alarms

      const { data: { data } } = res
      if (data.length === 0) continue
      const additionalInfo = data[0]?.latest.ENTITY_FIELD.additionalInfo.value
      if (!additionalInfo) patient.ambulance = data[0]?.latest.ENTITY_FIELD.name.value
      const { description } = JSON.parse(additionalInfo)
      if (description) patient.ambulance = description
      patient.caretakers = []

      for(const caretakerName in related.data) {
        const ids = related.data[caretakerName]
        if(ids.includes(patient.id)) {
          patient.caretakers.push(caretakerName)
        }
        // patient.caretakers = [...patient.caretakers, ids]
      }

    } catch (error) {
    }
  }

  for (let patient of patients) {
    if (patient.sys_assignment_status && patient.sys_assignment_devID) {
      patient.device = devicesObj[patient.sys_assignment_devID]
    }
  }


  return patients
};

export const fetchPatient = async (assetId: string): Promise<Patient> => {
  const url = '/asset/' + assetId
  return await (
    await apiClient.get(url)
  ).data
}

export const fetchDevicePositions = async (): Promise<Positions[]> => {
  const query = {
    entityFilter: {
      type: "entityType",
      resolveMultiple: true,
      entityType: "DEVICE"
    },
    entityFields: [
      {
        type: "ENTITY_FIELD",
        key: "name"
      },
      {
        type: "ENTITY_FIELD",
        key: "label"
      },
      {
        type: "ENTITY_FIELD",
        key: "type"
      }
    ],
    latestValues: [
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_assetID"
      },
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_status"
      },
      {
        type: "TIME_SERIES",
        key: "longitude"
      },
      {
        type: "TIME_SERIES",
        key: "latitude"
      }
    ],
    keyFilters: [
      {
        key: {
          key: "longitude",
          type: "TIME_SERIES"
        },
        valueType: "NUMERIC",
        predicate: {
          operation: "GREATER",
          value: {
            defaultValue: 0,
            dynamicValue: null
          },
          type: "NUMERIC"
        }
      },
      {
        key: {
          type: "ENTITY_FIELD",
          key: "type"
        },
        valueType: "BOOLEAN",
        predicate: {
          type: "COMPLEX",
          operation: "OR",
          predicates: [
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'LW-360HR',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'QL-GL320M',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'MT710',
                dynamicValue: null
              },
              type: "STRING"
            }

          ]
        }
      }
    ],
    pageLink
  }

  const { data: { data } } = await apiClient.post("/entitiesQuery/find", query)

  const payload: Positions[] = data.map((device: DeviceResponse): Positions => {
    const { latest: { ENTITY_FIELD, TIME_SERIES, ATTRIBUTE } } = device

    return {
      id: device.entityId.id,
      name: ENTITY_FIELD.name.value,
      label: ENTITY_FIELD.label.value,
      assetId: ATTRIBUTE.sys_assignment_assetID ? ATTRIBUTE.sys_assignment_assetID.value : '',
      assetName: "Nepřiřazeno",
      lat: parseFloat(TIME_SERIES.latitude.value),
      long: parseFloat(TIME_SERIES.longitude.value),
    }

  })

  for (let item of payload) {
    if (item.assetId) {
      const asset = await fetchPatient(item.assetId)
      item.assetName = asset.name
    }
  }

  return payload
}




export const fetchTelemetryKeys = async (assetId: string): Promise<string[]> => {
  const url = `/plugins/telemetry/ASSET/${assetId}/keys/timeseries`;
  return await (
    await apiClient.get(url)
  ).data
};

export const fetchTelemetryValues = async (assetId: string, keys: string[]): Promise<TelemetryValues> => {
  const url = `/plugins/telemetry/ASSET/${assetId}/values/timeseries?keys=${keys.join(',')}`;
  return await (
    await apiClient.get(url)
  ).data
};

export const fetchAllTelemetry = async (assetId: string, startTs: Date, endTs: Date): Promise<PatientTelemetry[]> => {
  if (!assetId) return []

  const keys = await fetchTelemetryKeys(assetId)
  if (keys.length === 0) return []

  const url = `/plugins/telemetry/ASSET/${assetId}/values/timeseries`;
  const params = {
    keys: keys.join(","),
    startTs: startTs.valueOf(),
    endTs: endTs.valueOf(),
    limit: 1000,
    orderBy: "ASC"
  }
  const { data } = await apiClient.request({ url, params })
  if (!data) return []
  let temp: { [key: string]: any } = {}
  for (let key in data) {
    const arr = data[key]

    for (let item of arr) {
      if (!temp[item.ts]) {
        temp[item.ts] = { ts: item.ts }
      }
      temp[item.ts][key] = item.value
    }
  }

  const sorted = Object.values(temp).sort((a, b) => (a.ts > b.ts) ? 1 : -1)

  return sorted
};

export const fetchCustomerInfo = async (customerId: string): Promise<any> => {
  const url = `/customer/${customerId}`;
  return await (
    await apiClient.get(url)
  ).data
}

export const fetchPatientsCount = async (): Promise<number> => {
  const query = {
    entityFilter: {
      type: "entityType",
      entityType: "ASSET"
    },
    entityFields: [
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_status"
      },
      {
        type: "ATTRIBUTE",
        key: "sys_assignment_assetID"
      },
    ],
    keyFilters: [
      {
        key: {
          type: "ENTITY_FIELD",
          key: "type"
        },
        valueType: "BOOLEAN",
        predicate: {
          operation: "EQUAL",
          value: {
            defaultValue: "pacient",
            dynamicValue: null
          },
          type: "STRING"
        }
      }
    ]
  }
  const response = await apiClient.post('/entitiesQuery/count', query)
  return await response.data
};

export const fetchDevicesCount = async (withActive?: boolean): Promise<number> => {

  const query: any = {
    entityFilter: {
      type: "entityType",
      entityType: "DEVICE"
    },
    keyFilters: [
      {
        key: {
          type: "ENTITY_FIELD",
          key: "type"
        },
        valueType: "BOOLEAN",
        predicate: {
          type: "COMPLEX",
          operation: "OR",
          predicates: [
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'LW-360HR',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'QL-GL320M',
                dynamicValue: null
              },
              type: "STRING"
            },
            {
              operation: "EQUAL",
              value: {
                defaultValue: 'MT710',
                dynamicValue: null
              },
              type: "STRING"
            }
          ]
        }
      }
    ]
  }

  const activeFilter =
  {
    key: {
      type: "ATTRIBUTE",
      key: "sys_assignment_status"
    },
    valueType: "BOOLEAN",
    predicate: {
      operation: "EQUAL",
      value: {
        defaultValue: true,
        dynamicValue: null
      },
      type: "BOOLEAN"
    }
  }


  if (withActive) {
    query.keyFilters = [...query.keyFilters, activeFilter]
  }

  const response = await apiClient.post('/entitiesQuery/count', query)
  return await response.data
};

export const fetchAlarms = async (pageSize: number, searchStatus: string, startTs?: number, endTs?: number): Promise<ThingsboardResponse<Alarm[]>> => {

  const auth = localStorage.getItem('_auth_state')

  const parsedAuth = JSON.parse(auth || '{}')
  const { userId } = parsedAuth

  const query: any = {
    "entityFilter": {
      "type": "entityType",
      "resolveMultiple": true,
      "entityType": "ASSET"
    },
    "pageLink": {
      "page": 0,
      "pageSize": pageSize,
      "searchPropagatedAlarms": true,
      "statusList": searchStatus.split(','), // ["CLEARED", "ACTIVE", "ACK", "UNACK"],
      "severityList": [
        "CRITICAL",
        "MAJOR"
      ],
      "sortOrder": {
        "key": {
          "key": "createdTime",
          "type": "ALARM_FIELD"
        },
        "direction": "DESC"
      },
    },
    keyFilters: [
      {
        key: {
          type: "ATTRIBUTE",
          key: "sys_owned_by"
        },
        valueType: "STRING",
        predicate: {
          operation: "EQUAL",
          value: {
            defaultValue: userId,
            dynamicValue: null
          },
          type: "STRING"
        }
      }
    ],
    "alarmFields": [
      {
        "type": "ALARM_FIELD",
        "key": "createdTime"
      },
      {
        "type": "ALARM_FIELD",
        "key": "type"
      },
      {
        "type": "ALARM_FIELD",
        "key": "severity"
      },
      {
        "type": "ALARM_FIELD",
        "key": "status"
      }
    ]

  }

  if (startTs && endTs) {
    query.pageLink.startTs = startTs
    query.pageLink.endTs = endTs
  } else {
    query.pageLink.timeWindow = 0
  }



  const { data: data } = await apiClient.request({ url: '/alarmsQuery/find', method: "POST", data: query })
  return data
}


export const postUserProfile = async (payload: UserProfileMutation) => {
  const { authUser, data } = payload
  const requests = []

  const profile = await apiClient.get(`/user/${authUser.userId}`)

  if (data.firstName || data.lastName) {
    const userData = {
      ...profile.data,
      firstName: data.firstName,
      lastName: data.lastName,
    }

    requests.push(apiClient.post('/user?sendActivationMail=false', userData))
  }

  if (data.currentPassword && data.newPassword) {
    const passwordChange = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    }
    requests.push(apiClient.post('/auth/changePassword', passwordChange))
  }

  const attributes = {
    sys_alarm_email: data.alarmEmail,
    sys_alarm_sms: data.alarmSms,
    sys_agreed_to_terms: data.agreed,
  }
  requests.push(postUserAttributes(authUser.userId, attributes))

  const response = Promise.all(requests)
  return response
}



export const fetchUserProfile = async (userId: string): Promise<UserProfile> => {

  const response: any = await Promise.all([
    apiClient.get(`/user/${userId}`),
    apiClient.get(`/plugins/telemetry/USER/${userId}/values/attributes/SERVER_SCOPE`)
  ])

  return {
    user: response[0].data,
    attributes: mapAttributesToObject(response[1].data)
  }
}

export const fetchAttributes = async (entityType: string, entityId: string | null, keys?: string[]): Promise<{ [key: string]: string }> => {
  const url = `/plugins/telemetry/${entityType}/${entityId}/values/attributes/SERVER_SCOPE`;
  let params = {}
  if (keys) {
    params = { keys: keys.join(',') }
  }
  try {
    const { data } = await apiClient.get(url, { params })
    return mapAttributesToObject(data)
  } catch (error) {
    return {}
  }
}

export const postUserAttributes = async (userId: string, attributes: any) => {
  const uri = `/plugins/telemetry/USER/${userId}/attributes/SERVER_SCOPE`
  const response = await apiClient.post(uri, attributes)
  return response
}

export const postAssetAttributes = async (assetId: string, attributes: any) => {
  const uri = `/plugins/telemetry/ASSET/${assetId}/attributes/SERVER_SCOPE`
  const response = await apiClient.post(uri, attributes)
  return response
}

export const fetchAllAssetAttributes = async (assetId: string): Promise<{ [key: string]: any }> => {
  const url = `/plugins/telemetry/ASSET/${assetId}/values/attributes`;
  const { data } = await apiClient.get(url)
  return mapAttributesToObject(data)
}


function mapAttributesToObject(attributes: any[]): any {
  const obj: any = {}
  for (let attr of attributes) {
    obj[attr.key] = attr.value
  }
  return obj
}



export const createAssetDeviceRelation = async (assetId: string, deviceId: string) => {
  const url = `/relation`
  return await apiClient.post(url, {
    from: {
      id: deviceId,
      entityType: "DEVICE"
    },
    to: {
      id: assetId,
      entityType: "ASSET"
    },
    type: "AssignedTo",
    typeGroup: "COMMON",
    additionalInfo: {}
  });
}

export const removeAssetDeviceRelation = async (assetId: string, deviceId: string) => {
  return await apiClient.delete("/relation", {
    params: {
      fromId: deviceId,
      fromType: 'DEVICE',
      relationType: "AssignedTo",
      toId: assetId,
      toType: 'ASSET'
    }
  })
}

export const createAlarmRelation = async (patientId: string, deviceId: string, payload: any) => {
  // find all entity groups
  const { customerId, userId } = getLocalAuth()
  const groups = await apiClient.get(`/entityGroups/CUSTOMER/${customerId}/ASSET`)

  // create asset group in ambulance if not exists
  const alertGroups = groups.data.filter((a: any) => a.name === "alertDefinitions")
  let entityGroup = alertGroups[0]
  if (!entityGroup) {
    const response = await apiClient.post('/entityGroup', {
      name: "alertDefinitions",
      type: "ASSET"
    })
    entityGroup = response.data
  }


  const definitions = definitionsToAlarm(payload)

  // find only alarms for the current patient
  const patientAlarms = await getAlarmsRelatedFromPatient(patientId)
  const userAlarms = await getAlarmsRelatedToUser(userId)

  const availableAlarms = []


  // we filter out alarms that are not related to the current patient
  for (const alarmId of patientAlarms) {
    if (!userAlarms.includes(alarmId)) continue
    availableAlarms.push(alarmId)
  }

  // we dont have the id's but the names of alarms are uniqe
  // unfortunatly, alarms dont provide names, so we need to fetch all assets one by one

  const alarms: any = []

  for (const alarmId of availableAlarms) {
    const alarm = await apiClient.get(`/asset/${alarmId}`)
    alarms.push(alarm.data)
  }

  // get all alarm names from definitions
  const alarmNames = definitions.map((d: any) => d.name)


  // This array will store the alarm names fetched from API
  const existingAlarmNames = alarms.map((alarm: any) => alarm.name);

  // Alarms to be created
  const alarmsToBeCreated = definitions.filter((def: any) => !existingAlarmNames.includes(def.name));

  // Alarms to be updated
  const alarmsToBeUpdated = alarms.filter((alarm: any) => alarmNames.includes(alarm.name));

  // Alarms to be deleted
  const alarmsToBeDeleted = alarms.filter((alarm: any) => !alarmNames.includes(alarm.name));


  // Create new alarms
  for (const alarm of alarmsToBeCreated) {
    await createNewAlarms(patientId, deviceId, payload, alarm, entityGroup.id.id, userId)
  }

  // Update existing alarms
  for (const alarm of alarmsToBeUpdated) {
    const updatedAlarmData = definitions.find((def: any) => def.name === alarm.name);
    await updateAlarmAttributes(alarm.id.id, updatedAlarmData)
  }

  // Delete alarms
  for (const alarm of alarmsToBeDeleted) {
    await apiClient.delete(`/asset/${alarm.id.id}`)
  }

}

export const fetchExistinAlarmCount = async (patientId: string) => {
  const { userId } = getLocalAuth()
  try {
    const allPatientsAlarms = await getAlarmsRelatedFromPatient(patientId)
    const allUsersAlarms = await getAlarmsRelatedToUser(userId)

    const availableAlarms = []
    for (const alarmId of allPatientsAlarms) {
      if (!allUsersAlarms.includes(alarmId)) continue
      const alarmAttributes = await fetchAttributes("ASSET", alarmId, ["sys_enabled"])
      if (!alarmAttributes.sys_enabled) continue
      availableAlarms.push(alarmId)
    }

    return availableAlarms.length
  } catch (error: any) {
    console.error(error.response.data)
  }
}


/**
 * getRelatedAlarmsFrom - FROM alert TO user
 * getRelatedAlarmsTo - FROM patient TO alert
 */
export const fetchExistingAlarmDefinitions = async (patientId: string) => {
  const { userId } = getLocalAuth()

  const allPatientsAlarms = await getAlarmsRelatedFromPatient(patientId)
  const allUsersAlarms = await getAlarmsRelatedToUser(userId)


  let result: any = {}
  const positions = []
  for (const alarmId of allPatientsAlarms) {
    // handle only alerts belonging to the user
    if (!allUsersAlarms.includes(alarmId)) continue
    const alert: any = await fetchAttributes("ASSET", alarmId)
    const definition = alarmToDefinition(alert)
    if (definition.positions) {
      positions.push(definition.positions)
    }
    delete definition.positions
    result = {
      ...result,
      ...definition,
      position: JSON.stringify(positions)
    }
  }
  return result
}

const createNewAlarms = async (assetId: string, deviceId: string, payload: any, definition: any, entityGroupId: string, userId: string) => {

  const asset = await apiClient.post(`/asset?entityGroupId=${entityGroupId}`, {
    name: definition.name,
    type: 'alertDefinition',
    assetProfileId: {
      entityType: "ASSET_PROFILE",
      id: ASSET_PROFILE[definition.type]
    },
    additionalInfo: {},
  })
  const url = `/relation`
  const fromRelation = {
    from: {
      id: asset.data.id.id,
      entityType: "ASSET"
    },
    to: {
      id: payload.sys_user_id,
      entityType: "USER"
    },
    type: "Notifies",
    typeGroup: "COMMON",
    additionalInfo: {}
  }

  const toRelation = {
    from: {
      id: assetId,
      entityType: "ASSET"
    },
    to: {
      id: asset.data.id.id,
      entityType: "ASSET"
    },
    type: "AlertedWith",
    typeGroup: "COMMON",
    additionalInfo: {}
  }
  await apiClient.post(url, fromRelation);
  await apiClient.post(url, toRelation);

  await updateAlarmAttributes(asset.data.id.id, definition)
}

const alarmToDefinition = (alarm: any) => {
  const res: any = {}
  const metric = alarm["sys_metricName"]
  res.sys_sendMail = alarm.sys_sendMail
  res.sys_sendSMS = alarm.sys_sendSMS
  res.sys_mail = alarm.sys_email
  res.sys_smsNo = alarm.sys_smsNo

  if (metric === "emergencySignal") {
    res.emergencySignal = alarm.sys_enabled
  } else if (metric === "manDownSignal") {
    res.sys_man_down = alarm.sys_enabled
  } else if (metric === "heartRate") {
    res.sys_hr_monitoring_status = alarm.sys_enabled
    res.sys_hr_monitoring_min = alarm.sys_minValue
    res.sys_hr_monitoring_max = alarm.sys_maxValue
  } else if (metric === "temperature") {
    res.sys_temperature_monitoring_status = alarm.sys_enabled
    res.sys_temperature_monitoring_min = alarm.sys_minValue
    res.sys_temperature_monitoring_max = alarm.sys_maxValue
  } else if (metric === "geo") {
    res.positions = {
      id: alarm.sys_idx || uniqueId(),
      alarm: alarm.sys_direction,
      radius: parseFloat(alarm.sys_distance) * 1000,
      lat: alarm.sys_latitudeRef,
      lng: alarm.sys_longitudeRef,
    }
  }
  return res
}

const definitionsToAlarm = (payload: any) => {
  const definitions = []

  const definition = {
    sys_sendMail: payload.sys_sendMail,
    sys_sendSMS: payload.sys_sendSMS,
    sys_smsNo: payload.sys_smsNo,
    sys_email: payload.sys_mail,
    sys_owned_by: payload.sys_user_id,
  }

  if (!payload.sys_sendMail) delete definition.sys_email
  if (!payload.sys_sendSMS) delete definition.sys_smsNo
  const name = `${payload.creator_name}_${payload.patient_name}_`

  if (payload.hasOwnProperty("emergencySignal")) {
    definitions.push({
      ...definition,
      sys_enabled: payload.emergencySignal,
      type: 'alertDefinition-emergency',
      name: name + 'emergency',
      sys_triggerValue: true,
      sys_metricName: 'emergencySignal',
    })
  }

  if (payload.hasOwnProperty("sys_man_down")) {
    definitions.push({
      ...definition,
      sys_enabled: payload.sys_man_down,
      type: 'alertDefinition-emergency',
      name: name + 'manDownSignal',
      sys_triggerValue: true,
      sys_metricName: 'manDownSignal',
    })
  }

  if (payload.hasOwnProperty("sys_temperature_monitoring_status")) {
    definitions.push({
      ...definition,
      sys_enabled: payload.sys_temperature_monitoring_status,
      type: 'alertDefinition-telemetry',
      name: name + 'temperature',
      sys_minValue: payload.sys_temperature_monitoring_min,
      sys_maxValue: payload.sys_temperature_monitoring_max,
      sys_metricName: 'temperature',
    })
  }

  if (payload.hasOwnProperty("sys_hr_monitoring_status")) {
    definitions.push({
      ...definition,
      sys_enabled: payload.sys_hr_monitoring_status,
      type: 'alertDefinition-telemetry',
      name: name + 'heartRate',
      sys_minValue: payload.sys_hr_monitoring_min,
      sys_maxValue: payload.sys_hr_monitoring_max,
      sys_metricName: 'heartRate',
    })
  }

  if (payload.positions && payload.positions.length > 0) {
    for (let [idx, position] of payload.positions.entries()) {
      definitions.push({
        ...definition,
        sys_enabled: true,
        type: 'alertDefinition-geo',
        name: name + `geo-${idx}`,
        sys_idx: position.id,
        sys_metricName: 'geo',
        sys_direction: position.alarm,
        sys_distance: position.radius,
        sys_latitudeRef: position.lat,
        sys_longitudeRef: position.lng,
      })

    }
  }

  return definitions
}

export const updateAlarmAttributes = async (alarmId: string, definition: any) => {
  try {
    const attributes: any = {
      ...definition
    }

    delete attributes.type
    delete attributes.name
    await apiClient.post(`/plugins/telemetry/ASSET/${alarmId}/attributes/SERVER_SCOPE`, attributes)

  } catch (error) {
    console.error(error)
  }
}


/**
 * Returns all alarms related to the user
 * FROM relation - alarm id
 * TO relation - user id
 * 
 * @param userId 
 * @returns 
 */
export const getAlarmsRelatedToUser = async (userId: string) => {
  const asset = await apiClient.post(`/relations`, {
    "filters": [
      {
        "relationType": "Notifies",
        "entityTypes": [
          "ASSET"
        ]
      }
    ],
    "parameters": {
      "rootId": userId,
      "rootType": "USER",
      "direction": "TO",
      "relationTypeGroup": "COMMON",
      "maxLevel": 1,
      "fetchLastLevelOnly": false
    }
  })

  return asset.data.map((a: any) => a.from.id)

}

/**
 * Returns all alarms related to the patient
 * FROM relation - patient id
 * TO relation - alarm id
 * 
 * @param patientId 
 * @returns 
 */
export const getAlarmsRelatedFromPatient = async (patientId: string) => {
  const asset = await apiClient.post(`/relations`, {
    "filters": [
      {
        "relationType": "AlertedWith",
        "entityTypes": [
          "ASSET"
        ]
      }
    ],
    "parameters": {
      "rootId": patientId,
      "rootType": "ASSET",
      "direction": "FROM",
      "relationTypeGroup": "COMMON",
      "maxLevel": 1,
      "fetchLastLevelOnly": false
    }
  })

  return asset.data.map((a: any) => a.to.id)
}

export const assignPatientToCaretaker = async (patientId: string, caratakerCustomerId: string) => {
  // find the customer group of the caretaker
  const patientPool = await fetchAttributes("CUSTOMER", caratakerCustomerId, ["sys_related_pacientGroupID"])
  // add patient to the new group - do not move, just add

  return await protectedURL.post('/', {
    uri: `/entityGroup/${patientPool.sys_related_pacientGroupID}/addEntities`,
    type: "POST",
    params: [patientId]
  })
}

export const unassignPatientFromCaretaker = async (patientId: string, caratakerCustomerId: string) => {
  // find the customer group of the caretaker
  const patientPool = await fetchAttributes("CUSTOMER", caratakerCustomerId, ["sys_related_pacientGroupID"])

  return await protectedURL.post('/', {
    uri: `/entityGroup/${patientPool.sys_related_pacientGroupID}/deleteEntities`,
    type: "POST",
    params: [patientId]
  })
}

export const fetchCaretaker = async (caretakerId: string) => {
  const response = await protectedURL.get(`/caretaker?id=${caretakerId}`)
  if (response.data.length !== 1) return null
  return response.data[0]
}

export const getLocalAuth = () => {
  const item = JSON.parse(localStorage.getItem("_auth_state") as any)
  return item
}


export const deleteAlert = async (alertId: string) => {
  return await apiClient.delete(`/asset/${alertId}`)
}