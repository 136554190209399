import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Patients from "../pages/Patients/PatientsPage";
import Alarms from '../pages/Alarms/AlarmsPage';
import Devices from '../pages/Devices/DevicesPage';
import NotFound from '../pages/NotFound';
import ProfilePage from "../pages/Profile/ProfilePage";
import Caretakers from "../pages/Caretakers/CaretakersPage";


const AmbulanceRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/profile" element={<ProfilePage />}></Route>
      <Route path="/patients" element={<Patients />}></Route>
      <Route path="/patients/:assetName" element={<Patients />}></Route>
      <Route path="/alarms" element={<Alarms />}></Route>
      <Route path="/devices" element={<Devices />}></Route>
      <Route path="/caretakers" element={<Caretakers />}></Route>
      <Route path="/*" element={<NotFound />}></Route>
    </Routes>
  );
};

export default AmbulanceRoutes;