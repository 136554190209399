import Icon from "./Icon"
import React, { useRef, useState } from "react";
import Label from "./Label";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const Datetime = ({ label, icon="", ...rest }) => {
  
  const [active, setActive] = useState(false);
  const [field, meta, helpers] = useField(rest);

  const inputRef = useRef()

  const {t} = useTranslation()

  const showPicker= ()=>{
    if(inputRef) {
      inputRef.current?.showPicker();
      helpers.setTouched(true)
    }
  }


  return (
    <React.Fragment>
      <div className={`form-group form-group-default ${ rest.required ? 'required': ''} ${ meta.touched && meta.error ? 'has-error': ''}`}>
        <div className="d-flex align-items-center">
          <div className="flex-1">
            <Label className={active ? "fade" : ""}>{label}</Label>
            <input
              className="form-control" 
              {...rest}
              {...field} 
              onFocus={() => setActive(true)}
              onBlur={(e) => {
                setActive(false)
                field.onBlur(e)
              }}
              ref={inputRef}
            />
          </div>
          <Icon role="button" onClick={showPicker}>calendar</Icon>
        </div>
        
      </div>
      {meta.touched && meta.error ? (
          <span className="error">{t(meta.error)}</span>
        ) : null}
    </React.Fragment>
  );
};

export default Datetime;
