import axios, { AxiosError } from "axios"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { loginApiClient } from "../../api/calls"
import Alert from "../../components/elements/Alert"
import Input from "../../components/elements/Input"
import * as Yup from 'yup';
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/spinner';
import { useAttributes } from "../../api/hooks"
import jwtDecode from "jwt-decode"
import { useSignIn } from "react-auth-kit"
import { useNavigate } from "react-router-dom"
import ForgotPasswordForm from "./ForgotPassword"


const SignupSchema = Yup.object().shape({
  username: Yup.string()
    .email('login.error.invalidEmail')
    .required('login.error.emailRequired'),
  password: Yup.string()
    .min(8, 'login.error.passwordMin')
    .max(20, 'login.error.passwordMax')
    .required('login.error.passwordRequired')
});




const LoginForm: React.FC<{
  setVerificationToken: Function, 
  setTotpToken: Function,
  setLoginValues: Function,
}> = ({setVerificationToken, setTotpToken, setLoginValues, ...rest})=> {
  
  const dispatch = useDispatch();
  const signIn = useSignIn()
  const navigate = useNavigate()

  const [showForgotForm, setShowForgotForm] = useState(false)

  const [userId, setUserId] = useState(null);
  const { data } = useAttributes("USER", userId, ['ignore_login']);
  const [tempToken, setTempToken] = useState({} as any)

  useEffect(() => {
    if (data) {
      if(!data.ignore_login) {
        setTotpToken(tempToken.token)
      } else {
        const token = jwtDecode(tempToken.token) as any
        signIn({
          token: tempToken.token,
          expiresIn: 3600 * 24,
          tokenType: "Bearer",
          refreshToken: tempToken.refreshToken,
          refreshTokenExpireIn: 3600 * 24,
          authState: token
        });
        navigate('/')
      }
    }
  }, [data, setTotpToken, tempToken, signIn, navigate]);
  



  const { t } = useTranslation();
  const [loginError, setLoginError] = useState("")
  return (

    // if showForgotForm, add it here, else render formik
    showForgotForm 
    ?
      <ForgotPasswordForm toggleLoginForm={setShowForgotForm}></ForgotPasswordForm>
    :
  
    <Formik
      initialValues={{ 
        username: '', 
        password: '',
      }}
      validationSchema={SignupSchema}
      onSubmit= { async (values: any, actions)=> {
        dispatch(startLoading());
        try {
          const response = await loginApiClient.post(
            '/auth/login',
            values,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          if(response.data.scope === "PRE_VERIFICATION_TOKEN") {
            setVerificationToken(response.data.token)
            setLoginError("")
          } else {
            const token = jwtDecode(response.data.token) as any
           
            localStorage.setItem("_auth_type", "Bearer")
            localStorage.setItem("_auth", response.data.token)
            setTempToken(response.data)
            setUserId(token.userId)
          }
        } catch (error: AxiosError|any) {
          if(axios.isAxiosError(error)) {
            setLoginError(error.response?.data.message)
          } else {
            setLoginError(error.message)
          }
          setVerificationToken("")
        } finally {
          setLoginValues(values)
          dispatch(stopLoading());
          return actions.setSubmitting(false)
        }
      }}
    >
    {({ isSubmitting, isValid }) => (
      <>
        <p className="mw-80 m-t-5">
          {t('login.subtitle')}
        </p>
        <Alert type="danger" text={loginError}/>
        <Form className="p-t-15">
          <Input
            label="login.form.email"
            name="username"
            type="email"
            className="form-control"
            placeholder="anna@neurosmart.cz"
            required
          />
          <Input
            label="login.form.password"
            name="password"
            type="password"
            placeholder="*********"
            className="form-control"
            required
            autoComplete="on"
          />
          <div className="row">
            <div className="col-md-12 d-flex align-items-center justify-content-end">
              <button
                aria-label=""
                className="btn btn-complete btn-lg m-t-10"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t("login.button")}
              </button>
            </div>
          </div>
          <div className="m-b-5 m-t-30">
            <a href="#" className="normal" onClick={()=>setShowForgotForm(true)}>{t("login.forgotPassword")}</a>

          </div>
        </Form>
      </>
      )}
    </Formik>
  )
}

export default LoginForm