import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable, flexRender } from '@tanstack/react-table';
import { isMobile } from 'react-device-detect';

type ComponentProps<T extends Record<string, unknown>> = {
  isLoading: boolean;
  withIndex?: boolean;
  columns: any[];
  data?: any[];
  columnVisibility?: Partial<any>;
  className?: string;
  defaultSorting?: { id: string; desc: boolean }[];
};

const Component = <T extends Record<string, unknown>>({
  isLoading,
  withIndex = false,
  columns,
  data = [],
  columnVisibility = {},
  className = '',
  defaultSorting,
}: ComponentProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting || [{ id: 'timestamp', desc: true }]);

  const table = useReactTable({
    columns,
    data,
    state: { 
      columnVisibility,
      sorting 
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table bordered hover className={className} responsive={isMobile}>
      <thead>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={index}>
            {withIndex && <th>#</th>}
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                <div onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading ? (
          <Spinner />
        ) : (
          table.getRowModel().rows.map((row, i) => (
            <tr key={row.id}>
              {withIndex && <td>{i + 1}</td>}
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

const Spinner: React.FC = () => {
  return (
    <tr>
      <td colSpan={100} style={{ textAlign: 'center' }}>
        <div className="progress-circle-indeterminate"></div>
      </td>
    </tr>
  );
};

export default Component;
