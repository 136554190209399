import { useTranslation } from "react-i18next"
import PageLayout from "../../components/layout/Page"
import Table from "../../components/elements/Table"
import useCaretakersTableColumns from "./CaretakersTableColumns"
import { useEffect, useState } from "react"
import CaretakersForm from "./CaretakersForm"
import { useCaretakers } from "../../api/hooks"


const Page = () => {
  const { t } = useTranslation()
  const caretakers = useCaretakers()
  const [filtered, setFiltered] = useState<any>([])
  const [showForm, setShowForm] = useState(false)
  const [currentCaretaker, setCurrentCaretaker] = useState("")


  const openForm = (id:string) => {
    setCurrentCaretaker(id)
    setShowForm(true)
  }

  const closeForm = async () => {
    setShowForm(false)
    await caretakers.refetch()
  }

  const columns = useCaretakersTableColumns(
    openForm,
  )

  useEffect(() => {
    if(!caretakers.data) return
    setFiltered(caretakers.data)
  }, [caretakers.data])

  return (
    <PageLayout>
      <div className="container-fluid container-fixed-lg bg-white">
        <div className="card card-transparent m-t-50">
          <div className="card-header ">
            <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between">
              <div className="card-title fs-16 p-b-10 bold">{t('caretakers.tableTitle')}</div>
            </div>
          </div>
          <div className="card-body">
            <Table
              withIndex={true}
              isLoading={false}
              columns={columns}
              data={filtered}
              defaultSorting={[{ id: 'groupName', desc: true }, { id: 'name', desc: true }]}
              columnVisibility={{ id: false }} 
              className="shrink sticky"/>
          </div>
        </div>
      </div>
      <CaretakersForm
        isOpen={showForm}
        caretakerId={currentCaretaker}
        onRequestClose={closeForm}
      />
    </PageLayout>
  )
}

export default Page