import { useTranslation } from "react-i18next"
import PageLayout from "../components/layout/Page"

const NotFound = ()=> {
  const { t } = useTranslation()
  return (
    <PageLayout className={"d-flex justify-content-center full-height-extra full-width align-items-center error-page"}>
      <div className="text-center">
        <h1 className="error-number">
          404
        </h1>
        <h2 className="semi-bold">
        {t("notFound.title")}
        </h2>
        <p className="p-b-10">
         {t("notFound.description")}
        </p>
      </div>
    </PageLayout>
  )
}

export default NotFound