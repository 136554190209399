import './assets/scss/style.scss';
import LoginPage from './pages/Login/Login';
import Base from './components/layout/Base';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Spinner from "./components/elements/Spinner";
import Alert from "./components/elements/Alert";
import { useIsAuthenticated } from "react-auth-kit";
import AmbulanceRoutes from "./routes/AmbulanceRoutes";
import CaretakerRoutes from "./routes/CaretakerRoutes";
import AdminRoutes from './routes/AdminRoutes';
import { ClipLoader } from "react-spinners";
import { UserRole, UserRoleContext } from "./providers/UserRoleProvider";
import { useContext, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { apiClient, getLocalAuth } from './api/calls';
import { useTranslation } from 'react-i18next';
import { AlertContext } from './context/AlertContext';
import { WebSocketContext } from './context/WsContext';

const App = () => {
  const { userRole } = useContext(UserRoleContext);
  const [ isConnected, setIsConnected ] = useState(false)
  const [message, setMessage] = useState<any>(null);
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const [notifications, setNotifications] = useState<{ text: string, id: number }[]>([]);

  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkUserId = async () => {
      const auth = getLocalAuth();
      if (auth && auth.userId) {
        setUserId(auth.userId);
      } else {
        // Retry mechanism in case userId is not available yet
        setTimeout(checkUserId, 1000); // Adjust delay as needed
      }
    };

    if (isAuthenticated()) {
      checkUserId();
    }
  }, [isAuthenticated]); // Depend on isAuthenticated to re-trigger check

  const { sendJsonMessage, lastMessage } = useWebSocket(socketUrl, {
    onOpen: (e) => {
      setIsConnected(true)
      const token = localStorage.getItem('_auth') as string;
      sendJsonMessage({
        type: "auth",
        token: token
      })
    },
    onClose: (e) => {
      setIsConnected(false)
    },
    shouldReconnect: (closeEvent) => true,
  });


  useEffect(() => {
    if (lastMessage?.data) {
      const msg = JSON.parse(lastMessage?.data)
      setMessage(msg)
      setNotifications((prev: any) => [...prev, { text: `Notifikace pro pacienta ${msg.details.sys_pacientName} - ${t('alarms.type.' + msg.details.alarm_type)}`, id: parseInt(msg.createdTime) }]);
    }
  }, [lastMessage])


  useEffect(() => {
    if (userId && socketUrl == null) {

      setSocketUrl(`wss://naramky.neurosmart.cz/internal/ws/`)
    }
  }, [userId, socketUrl])


  useEffect(() => {
    const updateServer = async () => {
      if (userId) {
        try {
          const uri = `/plugins/telemetry/USER/${userId}/attributes/SERVER_SCOPE`
          await apiClient.post(uri, {
            sys_user_id: userId,
          })
        } catch (error) {
          console.error("Error updating the attributes:", error);
        }
      }
    };

    updateServer();
  }, [userId]);


  let routesToRender;
  switch (userRole) {

    case UserRole.Ambulance:
      routesToRender = <AmbulanceRoutes />;
      break;

    case UserRole.Admin:
      routesToRender = <AdminRoutes />;
      break;

    case UserRole.Caretaker:
      routesToRender = <CaretakerRoutes />;
      break;

    default:
      routesToRender = <ClipLoader color="#ffffff" size={150} />;
  }

  const handleDismiss = (id: number) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    // navigate('/alarms')
  };


  return (
    <AlertContext.Provider value={message}>
      <WebSocketContext.Provider value={{ isConnected, setIsConnected }}>
        <Provider store={store}>
          <div className="App">
            <div className="notifications">
              {notifications.map((notification) => (
                <Alert
                  key={notification.id + Date.now()}
                  type="danger"
                  text={notification.text}
                  onClick={() => handleDismiss(notification.id)}
                  raw={true}></Alert>
              ))}

            </div>
            {isAuthenticated() ? (
              <Base>{routesToRender}</Base>
            ) : (
              <LoginPage />
            )}
            <Spinner />
          </div>
        </Provider>
      </WebSocketContext.Provider>
    </AlertContext.Provider>
  );
}

export default App;