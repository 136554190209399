import { useTranslation } from "react-i18next";
import Quickbar from "../../components/layout/Quickbar";

const AdminPatientsForm:React.FC<{
  isOpen: boolean,
  onRequestClose: Function, 
}> = ({isOpen, onRequestClose})=>{
  const { t } = useTranslation()

  return (
    <Quickbar
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width={40}
    >
   <div className="card card-transparent m-t-50">
      <div className="card-header">
        <div className="card-title">{t('admin.patients.form.title')}</div>
      </div>
    </div>
  </Quickbar>
  )
}

export default AdminPatientsForm;