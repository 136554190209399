import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { fetchAllTelemetry } from "../../api/calls";
import { PatientTelemetry } from "../../types";
import Datetime from "../../components/elements/Datetime";
import Table from '../../components/elements/Table';
import React from "react";
import Alert from "../../components/elements/Alert";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import Chart from "../../components/elements/Chart";
import Map from "../../components/elements/Map";
import Exporter from "../../components/elements/Exporter";

const AutoSubmit: React.FC<{ deviceId: string }> = ({ deviceId }) => {
  const { values, submitForm, isValid } = useFormikContext<{ start: string, end: string }>();
  useEffect(() => {
    if (deviceId && isValid) {
      submitForm();
    }
  }, [values, submitForm, isValid, deviceId]);
  return null;
};


const useTelemetryMutation = () => {
  return useMutation((payload) => {
    const { start, end, patientId } = payload as any
    return fetchAllTelemetry(patientId, new Date(start), new Date(end))
  })
}

const format = (dateObj: Date): string => {
  let year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  let day = dateObj.getDate().toString().padStart(2, '0');
  let hours = dateObj.getHours().toString().padStart(2, '0');
  let minutes = dateObj.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;

}

const TelemetrySchema = Yup.object().shape({
  start: Yup.date()
    .required("patients.telemetry.error.startRequired")
    .when('end', ([end], schema) => {
      return end && schema.max(end, "patients.telemetry.error.highStart")
    }),
  end: Yup.date()
    .required("patients.telemetry.error.endRequired")
    .test('isFuture', "patients.telemetry.error.highEnd", value => {
      return value <= new Date();
    })
});


type AlertError = {
  text?: string
  type?: "danger" | "warning" | "success"
}

const FormValuesResetter: React.FC<{ isOpen: boolean, }> = ({ isOpen }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (isOpen) {

      let start = new Date();
      start.setDate(start.getDate() - 1);
      let end = new Date();
      formik.setValues({
        start: format(start),
        end: format(end),
      });
    }
  }, [isOpen]);

  return null;
};

const TABLE_FIELDS: any = {
  "LW-360HR": ["heartRate", "distance", "battery", "steps", "calories", "temperature", "latitude", "longitude", "timestamp"],
  "QL-GL320M": ["batteryPercentage", "temperature", "latitude", "longitude", "timestamp"],
  "MT710": ["batteryVoltage", "latitude", "longitude", "timestamp"],
}

const DeviceTelemetry: React.FC<{
  deviceId: string,
  patientId: string,
  patientName: string,
  deviceType: string,
  isOpen: boolean,
}> = ({ deviceType, deviceId, patientId, patientName, isOpen, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataView, setDataView] = useState('table')
  const [data, setData] = useState<PatientTelemetry[]>([])
  const [alertError, setAlertError] = useState<AlertError>({})
  const { mutateAsync } = useTelemetryMutation()

  const [columnVisibility, setColumnVisibility] = useState({})

  useEffect(() => {
    if (!deviceType) return

    const tableColumnNames = [
      "heartRate",
      "distance",
      "battery",
      "steps",
      "calories",
      "temperature",
      "batteryPercentage",
      "latitude",
      "longitude",
      "timestamp",
      "batteryVoltage",
    ]

    const visibleColumns: any = {}
    const deviceFields = TABLE_FIELDS[deviceType]

    for (let column of tableColumnNames) {
      visibleColumns[column] = deviceFields.includes(column)
    }

    setColumnVisibility(visibleColumns)
  }, [deviceType, setColumnVisibility])

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'battery',
        header: t('patients.telemetry.table.battery'),
        accessorKey: 'battery',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " %" : "-"
          )
        }
      },
      {
        id: 'batteryPercentage',
        header: t('patients.telemetry.table.battery'),
        accessorKey: 'batteryPercentage',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " %" : "-"
          )
        }
      },
      {
        id: 'batteryVoltage',
        header: t('patients.telemetry.table.batteryVoltage'),
        accessorKey: 'batteryVoltage',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " V" : "-"
          )
        }
      },
      {
        id: 'heartRate',
        header: t('patients.telemetry.table.heartrate'),
        accessorKey: 'heartRate',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " bpm" : "-"
          )
        },
      },
      {
        id: 'temperature',
        header: t('patients.telemetry.table.temperature'),
        accessorFn: (row: PatientTelemetry) => {
          return row.temperature ? parseFloat(row.temperature).toFixed(2) : ""
        },
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " °C" : "-"
          )
        }
      },
      {
        id: 'calories',
        header: t('patients.telemetry.table.calories'),
        accessorKey: 'calories',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " kcal" : "-"
          )
        }
      },
      {
        id: 'distance',
        header: t('patients.telemetry.table.distance'),
        accessorKey: 'distance',
        cell: (info: any) => {
          const val = info.getValue()
          return (
            val ? val + " m" : "-"
          )
        }
      },
      {
        id: 'steps',
        header: t('patients.telemetry.table.steps'),
        accessorKey: 'steps',
      },
      {
        id: 'latitude',
        header: t('patients.telemetry.table.latitude'),
        accessorFn: (row: PatientTelemetry) => {
          return row.latitude ? parseFloat(row.latitude).toFixed(4) : "-"
        }
      },
      {
        id: 'longitude',
        header: t('patients.telemetry.table.longitude'),
        accessorFn: (row: PatientTelemetry) => {
          return row.longitude ? parseFloat(row.longitude).toFixed(4) : "-"
        }
      },
      {
        id: 'timestamp',
        header: t('patients.telemetry.table.timestamp'),
        accessorFn: (row: PatientTelemetry) => {
          return new Date(row.ts).toLocaleString('cs-CZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          })
        }
      },
    ],
    [t]
  )

  return (

    <div className="card card-transparent m-t-50">
      <div className="card-header">
        <Alert type={alertError.type} text={alertError.text} />
        <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between">
          <div className="card-title table-header">
            {patientName}
          </div>

          <Formik
            initialValues={{
              start: "",
              end: "",
            }}
            validationSchema={TelemetrySchema}
            onSubmit={async (values: any) => {
              values.deviceId = deviceId
              values.patientId = patientId
              setIsLoading(true)
              try {
                const response = await mutateAsync(values)
                setIsLoading(false)
                setData(response)
                if (response.length >= 1000) {
                  setAlertError({ type: "danger", text: t('patients.telemetry.warning.maxRecords') })
                } else {
                  setAlertError({})
                }

              } catch (error: any) {
                setAlertError({ type: "danger", text: error.response.data.message })
              }
            }}
          >
            {({ isSubmitting, isValid, values }) => (
              <React.Fragment>
                <FormValuesResetter isOpen={isOpen} />
                <Form className="d-flex flex-lg-row flex-column justify-content-lg-end">

                  <div className="m-r-20">
                    <Datetime
                      label={t('patients.telemetry.startLabel')}
                      type="datetime-local"
                      name="start"
                      disabled={isLoading}
                    />

                  </div>
                  <div className="m-r-20">
                    <Datetime
                      label={t('patients.telemetry.endLabel')}
                      type="datetime-local"
                      name="end"
                      disabled={isLoading}
                    />
                  </div>
                  <div className="m-r-20">
                    <Exporter data={data || []} fileName={`${patientName}-${values.start}-${values.end}`} />
                  </div>
                  <button
                    className={'btn btn-lg btn-success telemetry-btn'}
                    onClick={() => {
                      setDataView(dataView === 'table' ? 'chart' : 'table')
                    }
                    }
                  >
                    <Icon>{dataView === 'table' ? 'chart_alt' : 'table'}</Icon>
                  </button>
                  <AutoSubmit deviceId={deviceId} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>

      </div>
      <div className="card-body">
        {dataView == 'chart' &&
          <>
            <Chart data={data} deviceType={deviceType} />
            <div className="m-t-20"></div>
            <Map positions={data} />
          </>
        }
        {dataView == 'table' && <Table
          isLoading={isLoading}
          columns={columns}
          columnVisibility={columnVisibility}
          data={data} />}
      </div>
    </div>
  )
}
export default DeviceTelemetry