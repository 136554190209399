import React, { HTMLInputTypeAttribute, useState } from "react";
import Label from "./Label";
import { useField } from 'formik';
import { useTranslation } from "react-i18next"


const Input: React.FC<{
  name: string,
  label?: string, 
  readonly?: boolean, 
  disabled?: boolean,
  required?: boolean,
  prefix?: string,
  className?: string,
  step?: string|number,
  type?: HTMLInputTypeAttribute,
  placeholder?: string
  autoComplete?: string
  autoFocus?: boolean
}> = ({ label, readonly, prefix, disabled, required, ...props }) => {
  const {t} = useTranslation()


  const parseReadonly= (value: string, type?:string)=>{
    if(!type) return value


    if(prefix) {
      return `${t(prefix+value)}`
    }


    if(type === 'checkbox') {
      return value ? '✓' : '✗'
    }

    if(type === 'datetime-local') {
      if(isNaN(value as any) || value == '0') return '-'
      return new Date(value).toLocaleDateString('cs-CZ', { hour: 'numeric', minute: 'numeric' })
    }

    return value || '-'
  }

  const [active, setActive] = useState(false);
  // @ts-ignore
  const [field, meta] = useField(props);
  return (
    <React.Fragment>
      <div className={`form-group form-group-default ${required ? 'required': ''} ${ meta.touched && meta.error ? 'has-error': ''}`}>
        {label && (
          <div className="d-flex align-items-center">
            <Label className={active ? "fade" : ""}>{t(label)}</Label>
          </div>
        )}
        {readonly ? 
        <span>{parseReadonly(field.value, props.type) }</span>
        :
        <input
          {...field} 
          {...props}
          placeholder={props.placeholder ? t(props.placeholder) : undefined}
          onFocus={() => {
            setActive(true)
          }}
          disabled={disabled}
          onBlur={(e) => {
            field.onBlur(e)
            setActive(false)
          }}
        />
} 
        
      </div>
      {meta.touched && meta.error ? (
          <span className="error">{t(meta.error)}</span>
        ) : null}
    </React.Fragment>
  );
};

export default Input;
