import { useEffect, useState } from "react"
import { apiClient } from "../../api/calls";
import Map from "../../components/elements/Map";
import Input from "../../components/elements/Input";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";

const AlarmDetail: React.FC<{
  alarmId: string,
  isOpen: boolean,
  Ack: (id: string) => void
}> = ({ alarmId, isOpen, Ack }) => {

  const [a, setAlarmDetail] = useState<any>({})
  const [latLng, setLatLng] = useState<any>([])
  const [originator, setOriginator] = useState<any>({})

  const { t } = useTranslation()

  useEffect(() => {
    // get detail about alarm
    if (!isOpen) return;
    const query = async () => {
      const alarm = await apiClient.get(`/alarm/${alarmId}`)
      const org = await apiClient.get(`/asset/${alarm.data.originator.id}`)
      setOriginator(org.data)
      setLatLng([{
        latitude: alarm.data.details.sys_latitude,
        longitude: alarm.data.details.sys_longitude,
        ts: alarm.data.createdTime
      }])
      setAlarmDetail(alarm.data)
    }

    query()

  }, [isOpen, alarmId]);


  return (
    !a?.id ? null :
      <div className="card card-transparent m-t-50">
        <div className="card-header">
          <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between">
            {a.id ? <div className="card-title fs-16 p-b-10 bold">{a.details.sys_pacientName}</div> : null}
          </div>
        </div>
        <div className="card-body">
          <Map positions={latLng} />
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...a.details,
              ...a,
              originator
            }}
            onSubmit={async (values, actions) => {
              Ack(alarmId)
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, isValid, values, handleReset }) => (
              <Form>
                <p className="m-t-10">{t('alarm.details.info')}</p>
                <div className="form-group-attached readonly">
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.category")}
                        type="text"
                        name="alarm_type"
                        prefix="alarms.type."
                        readonly={true}
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.originator")}
                        type="text"
                        name="originator.name"
                        readonly={true}
                       />
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.latitude")}
                        type="text"
                        readonly={true}
                        name="sys_latitude"
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.longitude")}
                        type="text"
                        readonly={true}
                        name="sys_longitude"
                        />
                    </div>
                  </div>
                </div>

                <p className="m-t-10">{t('alarm.details.timestamps')}</p>
                <div className="form-group-attached readonly">
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.createdTime")}
                        type="datetime-local"
                        readonly={true}
                        name="createdTime"
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.startTs")}
                        type="datetime-local"
                        readonly={true}
                        name="startTs"
                        />
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.ackTs")}
                        type="datetime-local"
                        readonly={true}
                        name="ackTs"
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.clearTs")}
                        type="datetime-local"
                        readonly={true}
                        name="clearTs"
                        />
                    </div>
                  </div>
                </div>

                <p className="m-t-10">{t('alarm.details.notifications')}</p>
                <div className="form-group-attached readonly">
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.notifysms")}
                        type="checkbox"
                        readonly={true}
                        name="notifySMS"
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.smsNo")}
                        type="text"
                        readonly={true}
                        name="sys_smsNo"
                        />
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.notifyEmail")}
                        type="checkbox"
                        readonly={true}
                        name="notifyMail"
                       />
                    </div>
                    <div className="col-md-6">
                      <Input
                        className="form-control"
                        label={t("alarm.sys_mail")}
                        type="text"
                        readonly={true}
                        name="sys_mail"
                        />
                    </div>
                  </div>
                </div>

                <br />
                <button
                  aria-label=""
                  className="btn btn-success pull-right"
                  type="submit"
                  disabled={a.status.endsWith('_ACK')}
                >
                  {t('alarm.form.confirm')}
                </button>

              </Form>
            )}

          </Formik>
        </div>
      </div>
  )
}

export default AlarmDetail