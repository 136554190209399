import React, { useEffect, useState } from 'react'
import { useAttributes, useExistingAlarmDefinitions, usePatient } from "../../api/hooks";
import { fetchAttributes, getLocalAuth } from "../../api/calls";
import Quickbar from "../../components/layout/Quickbar";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import WatchForm from "./WatchForm";
import TrackerForm from "./TrackerForm";




const AlarmsSetupForm: React.FC<{
  patientId: string,
  cb: Function
  isOpen: boolean,
  onRequestClose: Function,
  deviceType: string,
}> = ({ deviceType, patientId, cb, isOpen, onRequestClose, ...rest }) => {
  const [wasReseted, setWasReseted] = useState(false)
  const [resetedData, setResetedData] = useState<any>({})
  const [internalOpen, setInternalOpen] = useState(false)
  const { userId } = getLocalAuth()
  const attributes = useAttributes("USER", userId, ["sys_alarm_email", "sys_alarm_sms"])
  const patient = usePatient(patientId)
  const patientAttributes = useAttributes("ASSET", patientId, ["sys_assignment_devID"])
  const alarms = useExistingAlarmDefinitions(patientId)

  const [initialData, setInitialData] = useState<any>({})

  const { t } = useTranslation();
  
  

  useEffect(() => {
    if (attributes.data && alarms.data && patientAttributes.data && patient.data) {
      const {userId, sub} = getLocalAuth()
      const defaultData = {
        sys_user_id: userId,
        patient_name: patient.data.name,
        creator_name: sub,
        patient_id: patientId,
        sys_assignment_devID: patientAttributes.data.sys_assignment_devID,
      }
      const data = {
        ...alarms.data,
        ...defaultData
      }
      if (!data.sys_smsNo) data.sys_smsNo = attributes.data.sys_alarm_sms
      if (!data.sys_mail) data.sys_mail = attributes.data.sys_alarm_email
      setInitialData(data)
      setResetedData(defaultData)
    }

  }, [attributes.data, alarms.data, patient.data, patientAttributes.data,  patientId, setInitialData, wasReseted])

  

  useEffect(()=>{
    setInternalOpen(isOpen)
    setWasReseted(false)
  }, [isOpen])

  const afterSubmit = async () => {
    cb()
    await alarms.refetch()
  }

  const requestedReset = () => {
    setWasReseted(true)

  }

  return (
    <Quickbar
      isOpen={internalOpen}
      onRequestClose={onRequestClose}
      width={40}
    >
      {alarms.isLoading || patient.isLoading || !deviceType ? (
        <div className="card card-transparent content-center vh-100">
          <ClipLoader color="black" size={40} />
        </div>
      ) : (
        <div className="card card-transparent m-t-50">
          <div className="card-header">
            <div className="card-title">{t('patients.setup.title')}</div>
          </div>
          <div className="card-body">
            {deviceType === 'LW-360HR' ? (
              <WatchForm 
              initialData={wasReseted ? resetedData : initialData} 
              callback={afterSubmit}
              requestReset={requestedReset}
              />
            ) : (
              <TrackerForm 
                initialData={wasReseted ? resetedData : initialData} 
                callback={afterSubmit}
                requestReset={requestedReset}
                deviceType={deviceType}
                />
            )}
          </div>
        </div>
      )}
    </Quickbar>
  )
}

export default AlarmsSetupForm