import { useTranslation } from "react-i18next";
import Quickbar from "../../components/layout/Quickbar";
import { Form, Formik } from "formik";

const AdminAmbulancesForm: React.FC<{
  isOpen: boolean,
  onRequestClose: Function,
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation()

  return (
    <Quickbar
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width={40}
    >
      <div className="card card-transparent m-t-50">
        <div className="card-header">
          <div className="card-title">{t('admin.ambulances.form.title')}</div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            <Form>
              
            </Form>
          </Formik>
        </div>
      </div>
    </Quickbar>
  )

}

export default AdminAmbulancesForm;