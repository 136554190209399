import React, { FC, useCallback } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

interface ExporterProps {
  data: any[]; 
  fileName: string;
}

const Exporter: FC<ExporterProps> = ({ data, fileName }) => {
  const { t } = useTranslation();

  const handleExport = useCallback(() => {
    // Translate keys and normalize data
    const translatedData = data.map((item) => {
      const newItem:any = {};
      Object.keys(item).forEach((key) => {
        let value = item[key];

        // Normalize timestamp
        if (key === 'ts') {
          value = new Date(item[key]).toLocaleString(); // Adjust this to match your locale or formatting requirements
        }

        // Translate key
        newItem[t("telemetry."+key)] = value;
      });

      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(translatedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    let buf = new ArrayBuffer(wbout.length);
    let view = new Uint8Array(buf);
    for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    saveAs(new Blob([buf], {type:"application/octet-stream"}), `${fileName}.xlsx`);
  }, [data, fileName, t]);

  return (
    <button 
      className='btn btn-danger btn-lg telemetry-btn' 
      onClick={handleExport}>{t('exporter.export')}
    </button>
  );
};

export default Exporter;
