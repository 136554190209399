import 'leaflet-draw/dist/leaflet.draw.css'
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { createAlarmRelation, fetchAttributes, getLocalAuth } from "../../api/calls";
import axios from "axios";
import { useEffect, useState } from "react";
import Alert from "../../components/elements/Alert";
import Toggle from "../../components/elements/Toggle";
import Input from "../../components/elements/Input";
import AlarmsSetupSchema from "./AlarmsSetupSchema";
import InputMap from "../../components/elements/InputMap";
import { LatLngExpression } from "leaflet";
import { useGeolocated } from "react-geolocated";

type AlertError = {
  text?: string
  type?: "danger" | "warning" | "success"
}

const emptyForm = {
  position: '',
  sys_hr_monitoring_status: false,
  sys_hr_monitoring_min: '',
  sys_hr_monitoring_max: '',
  sys_temperature_monitoring_status: false,
  sys_temperature_monitoring_min: '',
  sys_temperature_monitoring_max: '',
  sys_smsNo: '',
  sys_mail: '',
  emergencySignal: false,
  sys_sendMail: false,
  sys_sendSMS: false,
}

const WatchForm: React.FC<{
  initialData: any,
  callback: Function,
  requestReset: Function,
}> = ({ initialData, callback, requestReset }) => {
  const { t } = useTranslation()

  const [alertError, setAlertError] = useState<AlertError>({})
  const [initialValues, setInitialValues] = useState<any>( emptyForm)

  const [center, setCenter] = useState<LatLngExpression>([49.195061, 16.606836])
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const internalReset = () => {
    requestReset()
  }

  useEffect(() => {
    if (coords?.latitude && coords.longitude) {
      setCenter([coords?.latitude, coords?.longitude])
    }
  }, [coords, setCenter])

  useEffect(()=>{
    setInitialValues({
      ...emptyForm,
      ...initialData
    })
  }, [initialData])


  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={AlarmsSetupSchema}
      onSubmit={async (values, actions) => {

        try {

          let positions = values.position ? JSON.parse(values.position) : []
          positions = positions.map((position: any) => {
            return {
              ...position,
              // convert radis from meters to km with 3 decimal places
              radius: Math.round(position.radius / 1000 * 1000) / 1000
            }
          })

          delete values.position

          const payload: any = {
            ...values,
            positions,
          }
          delete payload.patient_id
          delete payload.sys_assignment_devID

          await createAlarmRelation(values.patient_id, values.sys_assignment_devID, payload)
        } catch (error: any) {
          if (axios.isAxiosError(error)) {
            setAlertError({ type: "danger", text: error.response?.data?.message })
          } else {
            setAlertError({ type: "danger", text: error.message })
          }
        } finally {
          actions.setSubmitting(false)
          callback()
        }
      }}
    >
      {({ isSubmitting, isValid, values, resetForm }) => (
        <><Alert type={alertError.type} text={alertError.text} />
          <Form>
            <p className="mw-80 m-b-25">
              {t('patients.setup.description')}
            </p>

            <div className="form-group-attached">
              <div className="row clearfix">
                <div className="col-md-6">
                  <Toggle
                    label={t("patients.setup.form.emailStatus")}
                    name="sys_sendMail" />
                </div>
                <div className="col-md-6">
                  <Toggle
                    label={t("patients.setup.form.smsStatus")}
                    name="sys_sendSMS" />
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.email")}
                    type="email"
                    placeholder="i.e. meno@email.com"
                    name="sys_mail"
                    disabled={!values["sys_sendMail"]}
                    required={values["sys_sendMail"]} />
                </div>
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.sms")}
                    type="tel"
                    placeholder="i.e. +420123456789"
                    name="sys_smsNo"
                    disabled={!values["sys_sendSMS"]}
                    required={values["sys_sendSMS"]} />
                </div>
              </div>
            </div>
            <br />

            <div className="form-group-attached">
              <Toggle
                label={t("patients.setup.form.emergency")}
                name="emergencySignal" />
            </div>

            <br />
            <div className="form-group-attached">
              <Toggle
                label={t("patients.setup.form.hrStatus")}
                name="sys_hr_monitoring_status" />
              <div className="row clearfix">
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.hrMin")}
                    type="number"
                    step="1"
                    placeholder="i.e. 50"
                    name="sys_hr_monitoring_min"
                    disabled={!values["sys_hr_monitoring_status"]}
                    required={values["sys_hr_monitoring_status"]} />
                </div>
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.hrMax")}
                    type="number"
                    step="1"
                    placeholder="i.e. 150"
                    name="sys_hr_monitoring_max"
                    disabled={!values["sys_hr_monitoring_status"]}
                    required={values["sys_hr_monitoring_status"]} />
                </div>
              </div>
            </div>
            <br />
            <p className="m-t-10">{t('patients.setup.temperatureTitle')}</p>
            <div className="form-group-attached">
              <Toggle
                label={t("patients.setup.form.temperatureStatus")}
                name="sys_temperature_monitoring_status" />
              <div className="row clearfix">
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.temperatureMin")}
                    type="number"
                    step="1"
                    placeholder="i.e. 31"
                    name="sys_temperature_monitoring_min"
                    disabled={!values["sys_temperature_monitoring_status"]}
                    required={values["sys_temperature_monitoring_status"]} />
                </div>
                <div className="col-md-6">
                  <Input
                    className="form-control"
                    label={t("patients.setup.form.temperatureMax")}
                    type="number"
                    step="1"
                    placeholder="i.e. 35"
                    name="sys_temperature_monitoring_max"
                    disabled={!values["sys_temperature_monitoring_status"]}
                    required={values["sys_temperature_monitoring_status"]} />
                </div>
              </div>
            </div>
            <br />

            <p className="m-t-10"> {t('patients.setup.locationTitle')}</p>
            <InputMap center={center} zoom={12} name="position"></InputMap>

            <br />
            <button
              aria-label=""
              className="btn btn-success pull-right"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {t('patients.setup.saveButton')}
            </button>
            <button
              className="btn btn-danger "
              onClick={internalReset}
              type="reset"
            >
              {t('patients.setup.clearButton')}
            </button>
          </Form></>
      )}

    </Formik>
  )

}

export default WatchForm