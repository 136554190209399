import { useContext, useEffect, useState } from 'react';
import { usePatientsWithDevices } from '../../api/hooks';
import Table from '../../components/elements/Table';
import Telemetry from './Telemetry';
import PageLayout from '../../components/layout/Page';
import Quickbar from '../../components/layout/Quickbar';
import AlarmsSetupForm from './AlarmsSetupForm';
import { useTranslation } from 'react-i18next';
import usePatientsTableColumns from './PatientsTableColumns';
import { useNavigate, useParams } from 'react-router-dom';
import { UserRole, UserRoleContext } from '../../providers/UserRoleProvider';

const Component = () => {
  const { userRole } = useContext(UserRoleContext);
  const [visibleColumns, setVisibleColumns] = useState<any>({})
  const { data, isLoading, refetch, isRefetching } = usePatientsWithDevices(userRole);
  const navigate = useNavigate()
  const { assetName } = useParams<{assetName: string}>()

  const [patientName, setPatientName] = useState("")
  const [detailOpen, setDetailOpen] = useState(false);
  const [alarmsSetupOpen, setAlarmsSetupOpen] = useState(false);
  const [patientId, setPatientId] = useState("")
  const [deviceType, setDeviceType] = useState("LW-360HR")
  const [deviceId, setDeviceId] = useState("")

  // refetch on mount
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if(userRole === UserRole.Ambulance) {
      setVisibleColumns({ id: false, device_id: false })
    } else if(userRole === UserRole.Caretaker) {
      setVisibleColumns({ id: false, device_id: false, ambulance: false, caretakers: false })
    }
  }, [userRole])

  useEffect(() => {
    if (assetName) {
      const patient = data?.find((asset) => asset?.id === assetName)
      if(!patient || !patient.device) return
      setPatientName(patient.name)
      handleViewTelemetry(patient.device.id, patient.id, patient.device.type, patient.device.name)
    }
  }, [assetName, data])


  const { t } = useTranslation();
  const closeQuickview = () => {
    if(assetName) {
      navigate(`/patients`)
    }
    setDetailOpen(false)
    setAlarmsSetupOpen(false)
  }


  const closeAndRefetch = async () => {
    setAlarmsSetupOpen(false)
    setDeviceType("")
    await refetch()
  }

  const handleViewTelemetry = (deviceId: string, patientId:string, type: string, name: string) => {
    setDeviceId(deviceId)
    setPatientId(patientId)
    setDeviceType(type)
    setPatientName(name)
    setDetailOpen(true)
  }

  const handleAlarmSetup = (id: string, deviceType: string, name: string) => {
    setPatientId(id)
    setDeviceType(deviceType)
    setPatientName(name)
    setAlarmsSetupOpen(true)
  }


  const columns = usePatientsTableColumns(
    handleViewTelemetry,
    handleAlarmSetup
  )

  return (
    <PageLayout>
      <div className="container-fluid container-fixed-lg bg-white">
        <div className="card card-transparent m-t-50">
          <div className="card-header ">
            <div className="card-title fs-16 p-b-10 bold">{t("patients.tableTitle")}</div>
          </div>
          <div className="card-body">
            <Table
              withIndex={true}
              isLoading={isLoading || isRefetching}
              columns={columns}
              data={data}
              columnVisibility={visibleColumns}
              defaultSorting={[
                { id: 'timestamp', desc: true }
              ]}
              className="shrink sticky" />
          </div>
        </div>
      </div>
      <Quickbar
        isOpen={detailOpen}
        onRequestClose={closeQuickview}
      >
        <Telemetry
          deviceId={deviceId}
          patientId={patientId}
          patientName={patientName}
          deviceType={deviceType}
          isOpen={detailOpen}
        />
      </Quickbar>
      <AlarmsSetupForm
        patientId={patientId}
        cb={closeAndRefetch}
        isOpen={alarmsSetupOpen}
        deviceType={deviceType}
        onRequestClose={closeQuickview}
      />

    </PageLayout>
  )
}



export default Component