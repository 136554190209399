import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from 'react-auth-kit'
import { BrowserRouter } from 'react-router-dom';
import refreshApi from './utils/refreshToken';
import { QueryClient, QueryClientProvider } from 'react-query';
import './i18n/i18n'
import { UserRoleProvider } from './providers/UserRoleProvider';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3 * (60 * 1000), // 3 mins
      cacheTime: 6 * (60 * 1000), // 6 mins
    },
  },
});

root.render(
  <React.StrictMode>
    <AuthProvider
       authType = {'localstorage'}
       authName={'_auth'}
       refresh={refreshApi}
       cookieDomain={window.location.hostname}
       cookieSecure={false}>
         <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <UserRoleProvider>
                <App />
              </UserRoleProvider>
            </BrowserRouter>
         </QueryClientProvider>

    </AuthProvider>
  </React.StrictMode>
);

