import { Route, Routes } from "react-router-dom";
import ProfilePage from "../pages/Profile/ProfilePage";
import NotFound from "../pages/NotFound";
import PatientsPage from "../pages/Patients/PatientsPage";
import AlarmsPage from "../pages/Alarms/AlarmsPage";
import Dashboard from "../pages/Dashboard/Dashboard";

const CaretakerRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/profile" element={<ProfilePage />}></Route>
        <Route path="/patients" element={<PatientsPage />}></Route>
        <Route path="/patients/:assetName" element={<PatientsPage />}></Route>
        <Route path="/alarms" element={<AlarmsPage />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    );
}

export default CaretakerRoutes;