import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { useMemo } from "react";

const useCaretakersColumnsDefitions = (
  openForm: (id: string) => void,
)=> {
  const { t } = useTranslation();
  const columns = useMemo(()=> [
    {
      id: 'id',
      accessorFn: (row: any)=>{
        return row.id.id
      }
    },
    {
      id: 'name',
      header: t("caretakers.table.name"),
      accessorFn: (row: any)=>{
        return row.firstName || row.lastName ? `${row.firstName || ''} ${row.lastName || ''}`.trim() : row.name;
      }
    },
    {
      id: 'groupName',
      header: t("caretakers.table.group"),
      accessorFn: (row: any)=>{
        return row.caretakerGroupName
      }
    },
    
    {
      id: 'patients',
      header: t("caretakers.table.patients"),
      accessorFn: (row: any)=>{
        return row.patientAssets?.length || 0
      }
    },
    {
      id: 'actions',
      header: t("caretakers.table.actions"),
      className: 'shrink',
      cell:  (info:any) => {
        const id = info.row.getValue('id')
        return (
          <div className='table-actions'>
            <span data-tooltip-id={"tooltip-edit"+id} >
              <button
                className='btn btn-primary'
                onClick={()=>{
                  openForm(id)
                }}
              >
                <Icon>edit</Icon>
              </button>
            </span>
            <Tooltip id={"tooltip-edit"+id} place="top">
              {t('caretakers.action.edit')}
            </Tooltip>
          </div>

        )
      }
    }
  ], [t, openForm])

  return columns;

}

export default useCaretakersColumnsDefitions