import { Trans, useTranslation } from "react-i18next"

const Footer = ()=> {
  const {t} = useTranslation()
  return (
    <div className="container-fluid container-fixed-lg footer">
      <div className="copyright sm-text-center">
        <p className="small no-margin pull-left sm-pull-reset">
          <span className="hint-text">{t('footer.copyright')}</span>
        </p>
        <p className="small no-margin pull-right sm-pull-reset">
          <Trans i18nKey="footer.help">
            <span className="hint-text">Do you need </span>
            <a href="https://soitron.com/contacts" target="_blank">Help?</a>
          </Trans>
        </p>
        <div className="clearfix"></div>
      </div>
    </div>
  )
}


export default Footer