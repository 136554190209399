import React, { useState } from "react";
import { useField } from 'formik';
import uniqid from 'uniqid'

const Toggle = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const uniqId = uniqid() 

  return (
    <React.Fragment>
      <div className={`form-group form-group-default form-check-group d-flex align-items-center ${ props.required ? 'required': ''} ${ meta.touched && meta.error ? 'has-error': ''}`}>
        <div className="form-check switch switch-lg success full-width right m-b-0">
            <input
              ref={props.ref}
              type="checkbox"
              id={uniqId}
              {...field} 
              {...props}
              checked={field.value}
            />

          <label htmlFor={uniqId}>{label}</label>
        </div>
      </div>
      {meta.touched && meta.error ? (
          <span className="error">{meta.error}</span>
        ) : null}
    </React.Fragment>
  );
};

export default Toggle;
