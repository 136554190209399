
import { useField } from 'formik'
import React, { useState, useRef, useEffect } from 'react'
import Label from "./Label";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";



const Select = ({ label, options, valueKey, labelKey, ...props }) => {
  const [active, setActive] = useState(false);
  const [field, meta, helper] = useField(props);
  const [preselected, setPreselected] = useState([]);

  const TypeaheadRef = useRef(null)
  useEffect(() => {
    const el = TypeaheadRef.current
    if (!field.value && el) {
      el.clear()
    }

    if(field.value && options.length) {
      const option = options.find(option => option[valueKey] === field.value) 
      if(!option) {
        setPreselected([])
      } else {
        setPreselected([option])
      }
    }
  }, [field.value, options, valueKey, setPreselected])

  return (
    <React.Fragment>
      <div className={`form-group form-group-default typehead typehead-select  ${props.required ? 'required' : ''} ${meta.touched && meta.error ? 'has-error' : ''}`}>
        <Label className={active ? "fade" : ""}>{label}</Label>
        {!Array.isArray(options)
          ? <p>No data</p>
          : <Typeahead
            id={props.name}
            {...props}
            ref={TypeaheadRef}
            options={options}
            multiple={false}
            selected={preselected}
            filterBy={() => true}
            onChange={(selected) => {
              const value = selected.length > 0 ? selected[0][valueKey] : null;
              helper.setTouched(true)
              helper.setValue(value);
              if (props.blurOut) {
                TypeaheadRef.current.blur()
              }
            }}
            onFocus={() => {
              setActive(true)
            }}
            onBlur={(e) => {
              setActive(false)
              helper.setTouched(true)
            }}
            allowNew={false}
            labelKey={labelKey}
            {...(meta.touched && meta.error
              ? { isInvalid: true, className: "is-invalid" }
              : { isValid: true })}
            {...props}
          />
        }
      </div>
    </React.Fragment>

  )
}

export default Select