import React from 'react';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { Alarm, Device, Patient, PatientTelemetry, Positions, TelemetryValues, ThingsboardResponse, UserProfileMutation } from '../types';
import { fetchAlarms, fetchAllAssetAttributes, fetchAllTelemetry, fetchAttributes, fetchCaretaker, fetchCaretakers, fetchCustomerInfo, fetchDevicePositions, fetchDevices, fetchDevicesCount, fetchExistingAlarmDefinitions, fetchPatient, fetchPatients, fetchPatientsCount, fetchPatientsWithDevices, fetchTelemetryValues, fetchUserProfile, postUserProfile } from './calls';

export const usePatients = (): UseQueryResult<Patient[]> => {
  const response =  useQuery('patients', () => fetchPatients());
  const { data } = response

  const decoratedData: Patient[] = React.useMemo(() => {
    if (!data) return [];
    return data
  }, [data])
  
  if(response.data) {
    response.data = decoratedData
  }
  return response
};

export const useCaretakers = (): UseQueryResult<any> => {
  return useQuery('caretakers', ()=>fetchCaretakers())
}

export const usePatient = (assetId: string): UseQueryResult<any> => {
  return useQuery(['patient', assetId], () => fetchPatient(assetId), {
    enabled: !!assetId,
  });
}; 

export const usePatientsCount = (): UseQueryResult<number> => {
  return useQuery('patients_count', () => fetchPatientsCount());
};

export const useDevicesCount = (): UseQueryResult<number>[] => {
  const totalDevices = useQuery('devices_count', () => fetchDevicesCount());
  const totalActiveDevices = useQuery('active_devices_count', () => fetchDevicesCount(true));
  
  return [totalDevices, totalActiveDevices]
};


export const useDevices = (): UseQueryResult<Device[]> => {
  return useQuery(['devices'], () => fetchDevices(false));
};

export const useDevicesWithoutPatient = (): UseQueryResult<Device[]> => {
  return useQuery('unassigned', () => fetchDevices(true));
};

export const usePatientsWithDevices = (role:string)=>{
  return useQuery(['assigned', role], ()=>fetchPatientsWithDevices(role), {
    enabled: !!role,
  })
}
export const useDevicePositions = (): UseQueryResult<Positions[]> =>{
  return useQuery(['positions'], ()=>fetchDevicePositions())
}

export const useTelemetry = (assetId: string, startTs: Date, endTs: Date): UseQueryResult<PatientTelemetry[]> =>{
  const response = useQuery(['all_telemetry', assetId, startTs, endTs], ()=>fetchAllTelemetry(assetId, startTs, endTs), {
    enabled: !!assetId,
  })
  
  return response
}

export const useAttributes = (entityType: string, entityId: string|null, keys?: string[]): UseQueryResult<{[key:string]:any}> =>{
  return useQuery([entityType, entityId, keys], ()=>fetchAttributes(entityType, entityId, keys), {
    enabled: !!entityId && !!entityType,
  })
}


export const useTelemetryValues = (deviceId: string, keys: string[]): UseQueryResult<TelemetryValues> =>{
  const response = useQuery(["telemetry", deviceId, keys], ()=>fetchTelemetryValues(deviceId, keys), {
    enabled: !!deviceId,
  })
  return response
}

export const useAlarms = (pageSize: number = 100,  searchStatus= "ANY"): UseQueryResult<ThingsboardResponse<Alarm[]>> =>{
  return useQuery(['alarms', pageSize, searchStatus], ()=>fetchAlarms(pageSize, searchStatus))
}
export const useAlarmsWithTime = (pageSize: number = 100,  searchStatus= "ANY", start: number, end: number ): UseQueryResult<ThingsboardResponse<Alarm[]>> =>{
  return useQuery(['alarms_with_time', pageSize, searchStatus, start, end], ()=>fetchAlarms(pageSize, searchStatus, start, end))
}

export const useRefetchAlarms = (): UseMutationResult<ThingsboardResponse<Alarm[]>, unknown, { pageSize: number, searchStatus: string, start?: number, end?: number }, unknown> => {
  return useMutation(({ pageSize, searchStatus, start, end }) => fetchAlarms(pageSize, searchStatus, start, end));
}
export const useUserProfileMutation = ()=> {
  return useMutation((params: UserProfileMutation)=>postUserProfile(params))
}


export const useUserProfile = (userId: string)=> {
  return useQuery(['user_profile', userId], ()=>fetchUserProfile(userId))
}

export const useAllAssetAttributes = (assetId: string) => {
  return useQuery(['all_asset_attributes', assetId], ()=>fetchAllAssetAttributes(assetId), {
    enabled: !!assetId,
  })
}

export const useCustomerInfo = (customerId: string) => {
  return useQuery(['customer_info', customerId], ()=> fetchCustomerInfo(customerId), {
    enabled: !!customerId,
  })
}

export const useExistingAlarmDefinitions = (patientId: string) => {
  return useQuery(['existing_alarms',patientId], ()=> fetchExistingAlarmDefinitions(patientId), {
    enabled: !!patientId,
  })
}

export const useCaretaker = (caretakerId: string): UseQueryResult<any> => {
  return useQuery(['caretaker', caretakerId], ()=> fetchCaretaker(caretakerId), {
    enabled: !!caretakerId,
  })
}