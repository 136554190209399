import { useEffect, useState } from "react"
import Quickbar from "../../components/layout/Quickbar"
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import Input from "../../components/elements/Input";
import Select from "../../components/elements/Select";
import { createAssetDeviceRelation } from "../../api/calls";

const DevicePair: React.FC<{
  device: any,
  isOpen: boolean,
  list: any,
  onRequestClose: Function,
}> = ({ device, isOpen, onRequestClose, list }) => {
  const { t } = useTranslation();
  const [internalOpen, setInternalOpen] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({})
  const [filteredList, setFilteredList] = useState<any>([])

  useEffect(() => {
    if(!device || !list) return
    const filtered = []
    for(let item of list) {
      if(item.device && item.device !== device.id) {
        continue
      }
      filtered.push(item)
    }
    setFilteredList(filtered.sort((a:any, b:any) => a.label.localeCompare(b.label)))
    const existing = filtered.find((item:any)=> item.device === device.id)
    setInitialValues({
      device: device.name,
      patient: existing?.id
    })

  }, [list, device, setFilteredList, setInitialValues])


  useEffect(() => {
    setInternalOpen(isOpen)
  }, [isOpen, setInternalOpen])

  return (
    <Quickbar
      isOpen={internalOpen}
      onRequestClose={onRequestClose}
      width={40}
    >
      <div className="card card-transparent m-t-50">
        <div className="card-header">
          <div className="card-title">{t('patients.pair.title')}</div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              try {
                await createAssetDeviceRelation(values.patient, device.id)
                onRequestClose()
              } catch (error) {
                console.error(error)
              } finally {
                actions.setSubmitting(false)
              }
            }}
          >
           {({ isSubmitting, isValid}) => (
            <Form>
              <p>{t('devices.pair.description')}</p>
              <br/>
              <div className="form-group-attached">
                <div className="row clearfix">
                  <div className="col-md-6">
                    <Input
                      className="form-control"
                      label={t("devices.pair.form.device")}
                      type="text"
                      name="device"
                      readonly />
                  </div>
                  <div className="col-md-6">
                    <Select
                      name="patient"
                      label={t("devices.pair.form.patients")}
                      options={filteredList}
                      valueKey={'id'}
                      labelKey={'label'}
                      required />
                  </div>
                </div>
              </div>
              <br/>
              <button
                aria-label=""
                className="btn btn-success pull-right"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t('devices.pair.pairButton')}
              </button>
            </Form>
             )}
          </Formik>
        </div>
      </div>
    </Quickbar>

  )
}
export default DevicePair