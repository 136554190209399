import Widget from '../../components/elements/Widget'
import { useDevicesCount, usePatientsCount, useAlarms, useCustomerInfo } from '../../api/hooks';
import PageLayout from '../../components/layout/Page';
import AlarmTable from './AlarmTable';
import DeviceMap from './DeviceMap';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'react-auth-kit';
import { UserRole, UserRoleContext } from '../../providers/UserRoleProvider';
import { useContext, useEffect } from 'react';
import { AlertContext } from '../../context/AlertContext';

const Dashboard = ()=> {
  const message = useContext(AlertContext);
  const auth = useAuthUser()
  const customer = useCustomerInfo(auth()?.customerId)
  const patientsCount = usePatientsCount()
  const devicesCount = useDevicesCount()
  // get user role from UserRoleProvider.tsx
  const { userRole } = useContext(UserRoleContext);  


  const alarms = useAlarms(1000, "ACTIVE, UNACK")
  const { t } = useTranslation();

  useEffect(()=>{
    refreshData()
  }, [])

  useEffect(()=>{
    if(message) {
      refreshData()
    }
  }, [message])

  const refreshData = ()=> {
    if(alarms && typeof alarms.refetch === "function") {
      alarms.refetch()
    }
    if(patientsCount &&  typeof patientsCount.refetch === "function") {
      patientsCount.refetch()
    }
    if(devicesCount && devicesCount.length > 0 && typeof devicesCount[0].refetch === "function") {
      devicesCount[0].refetch()
    }
    if(devicesCount && devicesCount.length > 0 && typeof devicesCount[1].refetch === "function") {
      devicesCount[1].refetch()
    }
  }

  
  return(
    <PageLayout className="gutter-sm">
      <div className="container padding-25 sm-padding-10">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-sm-6 m-b-10">
                <Widget 
                  url="/profile"
                  isLoading={customer.isLoading}
                  className="bg-info"
                  title={t('role.'+ userRole)} // pass userRole to translation
                  description={t('dashboard.ambulanceLabel')} 
                >
                  {customer?.data?.additionalInfo?.description} 
                </Widget>
              </div>
              <div className="col-sm-6 m-b-10">
                <Widget 
                  url="/patients"
                  isLoading={patientsCount.isLoading}
                  className="bg-success" 
                  title={t('dashboard.patients')} 
                  description={t('dashboard.patientsLabel')} 
                >
                  {patientsCount.data}
                </Widget>
              </div>
            </div>
            <div className="row">
             
              <div className="col-sm-6 m-b-10">
                <Widget 
                  url="/alarms"
                  isLoading={alarms.isLoading}
                  className="bg-danger" 
                  title={t('dashboard.alarms')} 
                  description={t('dashboard.alarmsLabel')} 
                >
                    {alarms.data ? alarms.data.totalElements : 0} 
                  </Widget>
              </div>
              <div className="col-sm-6 m-b-10">
                {userRole === UserRole.Ambulance && (
                  <Widget 
                    url="/devices"
                    isLoading={ devicesCount[0].isLoading || devicesCount[1].isLoading }
                    className="bg-complete"
                    title={t('dashboard.devices')}  
                    description={t('dashboard.devicesLabel')} 
                  >
                    {devicesCount[0].data}/{devicesCount[1].data}
                  </Widget>

                )}
              </div>
              
            </div>
          </div>

          <div className="col-lg-6 col-12 p-b-10">
            <AlarmTable/>
          </div>
        </div>
        <div className='row'>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <DeviceMap/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}





export default Dashboard