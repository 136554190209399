import axios, { AxiosError } from "axios"
import { Form, Formik } from "formik"
import jwtDecode from "jwt-decode"
import { loginApiClient } from "../../api/calls"
import Alert from "../../components/elements/Alert"
import Input from "../../components/elements/Input"
import * as Yup from 'yup';
import { useSignIn } from "react-auth-kit"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useTranslation } from "react-i18next"


const TotpSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]{6}$/, 'login.error.verificationLength')
    .required("login.error.verificationRequired")
})




const TotpForm: React.FC<{
  setVerificationToken: Function, 
  verificationToken: string
}> = ({setVerificationToken, verificationToken, ...rest})=> {
  const signIn = useSignIn()
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState("")
  const { t } = useTranslation();

  return (
    <>
    <p className="mw-80 m-t-5">
      {t('login.totp.subtitle')}
    </p>
    <Formik
      initialValues={{ 
        code: '', 
      }}
      validationSchema={TotpSchema}
      onSubmit= { async (values: any, actions)=> {
        try {
          const response = await loginApiClient.post(
            '/auth/2fa/verification/check',
            {}, // has to be empty
            {
              headers: {
                'Authorization': `Bearer ${verificationToken}`
              },
              params: {
                providerType: 'TOTP',
                verificationCode: values.code
              }
            },
          )

          const decodedToken = jwtDecode(response.data.token) as any
            
          signIn({
            token: response.data.token,
            expiresIn: 3600 * 24,
            tokenType: "Bearer",
            refreshToken: response.data.refreshToken,
            refreshTokenExpireIn: 3600 * 24,
            authState: decodedToken
          })
          setVerificationToken("")
          navigate("/")

        } catch (error: AxiosError|any) {
          if(axios.isAxiosError(error)) {
            setLoginError(error.response?.data.message)
          } else {
            setLoginError(error.message)
          }
          actions.resetForm()
        } finally {
          actions.setSubmitting(false)
        }
      }}
    >
    {({ isSubmitting, isValid }) => (
      <>
        <Alert type="danger" text={loginError}/>
        <Form className="p-t-15">
          <Input
            label="Verification Code"
            name="code"
            type="text"
            placeholder="i.e 880417"
            className="form-control"
            required
            autoFocus
          />
          <div className="row">
            <div className="col-md-12 d-flex align-items-center justify-content-end">
              <button
                aria-label=""
                className="btn btn-complete btn-lg m-t-10"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
               {t("login.button")}
              </button>
            </div>
          </div>
        </Form>
      </>
      )}
    </Formik>
  </>
  )
}

export default TotpForm