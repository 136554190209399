import React, { useState } from "react";
import { useField } from 'formik';
import uniqid from 'uniqid'
import { useTranslation } from "react-i18next";

const Checkbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const uniqId = uniqid() 
  const {t } = useTranslation();

  return (
    <React.Fragment>
      <div className={`form-check primary  m-t-0  ${ props.required ? 'required': ''} ${ meta.touched && meta.error ? 'has-error': ''}`}>
        <input
          type="checkbox"
          id={uniqId}
          {...field} 
          {...props}
          checked={field.value}
        />
        { label && (
         <label htmlFor={uniqId}>{label}</label>
        )}
      </div>
      {meta.touched && meta.error ? (
          <span className="error">{t(meta.error)}</span>
        ) : null}
    </React.Fragment>
  );
};

export default Checkbox;
