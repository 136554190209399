import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const activeStyle = {
  color: "#ffffff",
}


interface ISidebarItem {
  url: string, 
  title?: string, 
  subTitle?: string, 
  iconWrapperClass?: string,
  topMargin?: string,
  icon?: any,
  children?: any,
  subMenuItem?: any
}

const selectToggle = (state:any) => state.active


const Component: React.FC<ISidebarItem> = ({
  url = "",
  title,
  subTitle ,
  iconWrapperClass ,
  topMargin,
  icon,
  children ,
  subMenuItem,
  ...props
}) => {

  const toggleValue = useSelector(selectToggle)

  const dispatch = useDispatch()

  return (
    <li className={topMargin}>
      <NavLink
        end
        to={url}
        {...props}
        className="detailed"
        style={({ isActive }) => ({
          ...(isActive ? activeStyle : null),
        })}
        onClick={()=>{
          if(toggleValue) dispatch({type: "navigation/toggleMenu"})
        }}
      >
         <span
            className={subTitle || subMenuItem ? "title" : "title line-height-40"}
          >
          {title}
          </span>
          {subTitle && <span className="details">{subTitle}</span>}
      </NavLink>
      <span className={`${iconWrapperClass} icon-thumbnail`}>{icon}</span>
      {children}
    </li>
  )
}

export default Component