import { useTranslation } from "react-i18next"
import PageLayout from "../../components/layout/Page"
import useAmbulancesTableColumns from "./AdminAmbulances"
import Table from "../../components/elements/Table"
import Icon from "../../components/elements/Icon"
import { useState } from "react"
import AdminAmbulancesForm from "./AdminAmbulancesForm"

const Page = () => {
  const { t } = useTranslation()

  const data: any = []
  const columns = useAmbulancesTableColumns()

  const [showForm, setShowForm] = useState(false)

  const closeForm = () => {
    setShowForm(false)
  }

  return (
    <PageLayout>
      <div className="container-fluid container-fixed-lg bg-white">
        <div className="card card-transparent m-t-50">
          <div className="card-header ">
            <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between">
              <div className="card-title fs-16 p-b-10 bold">{t('ambulances.tableTitle')}</div>
              <button
                className='btn btn-success'
                onClick={() => {
                  setShowForm(true)
                }}
              >
                {t('admin.ambulances.addItem')} <Icon>plus</Icon>
              </button>
            </div>
          </div>
          <div className="card-body">
            <Table
              withIndex={true}
              isLoading={false}
              columns={columns}
              data={data}
              columnVisibility={{ id: false }} 
              className="shrink sticky"/>
          </div>
        </div>
      </div>
      <AdminAmbulancesForm
        isOpen={showForm}
        onRequestClose={closeForm}
      />

    </PageLayout>
  )
}

export default Page