import { useEffect, useRef, useState, useContext } from "react";
import { useSignOut } from "react-auth-kit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useAuthUser} from 'react-auth-kit'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { WebSocketContext } from "../../context/WsContext";


const Breadcrumb = ()=> {
  const location = useLocation()
  const { t } = useTranslation();

  if(location.pathname == '/') return (
    <li className="breadcrumb-item active">{t('sidebar.dashboard')}</li>
  )
  let breadcrumbs = location.pathname.split('/')
  breadcrumbs = breadcrumbs.filter(b=>b!=="")
  return (
    <>
      {breadcrumbs.map((b, index) => (
        <li className="breadcrumb-item active" key={b}>
          {index === 0 ? t(`sidebar.${b}`) : b}
        </li>
      ))}
    </>
  )
}


const Component = ()=>{
  const { isConnected } = useContext(WebSocketContext);

  const [shouldShowProfile, setShouldShowProfile] = useState(false);
  const navigate = useNavigate()
  const signOut = useSignOut()
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const dispatch = useDispatch()

  const logout = ()=> { 
    signOut()  
    navigate("/login")
  }


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const target = dropdownRef.current as any;
      if (target && !target.contains(event.target)) {
        closeProfile();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const auth = useAuthUser()

  const showInitials = ()=>{
    const {firstName, lastName, sub} = auth() as any
    if(!firstName || !lastName) return sub.charAt(0).toUpperCase()
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  }

  const closeProfile = () => {
    setShouldShowProfile(false)
  }

  return (
    <div className={`header`}>
      <div
        className="btn-link toggle-sidebar d-lg-none pg-icon btn-icon-link"
        onClick={() => {
         dispatch({type: 'navigation/toggleMenu'})
        }}
      >
        menu
      </div>
      <ul className="header-nav breadcrumb p-l-50">
        <li className="breadcrumb-item">
          <Link to="/">{t('header.homeLink')}</Link>
        </li>
       <Breadcrumb />
      </ul>

      <div className="d-flex align-items-center p-r-50">
          <div
            ref={dropdownRef}
            className={`dropdown pull-right d-lg-block d-none ${
              shouldShowProfile ? "show" : ""
            }`}
          >
            <button
              className={isConnected ? "profile-dropdown-toggle connected" : "profile-dropdown-toggle"}
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={shouldShowProfile ? "true" : "false"}
              aria-label="profile dropdown"
            >
              <span className="thumbnail-wrapper d32 circular inline">
                <div 
                  className="thumbnail-initials"
                  onClick={() => setShouldShowProfile((prevState) => !prevState)}
                >
                  {showInitials()}
                </div>
              </span>
            </button>
            <div
              style={{ width: "fit-content" }}
              className={`dropdown-menu dropdown-menu-right profile-dropdown ${
                shouldShowProfile ? "show" : ""
              }`}
              role="menu"
            >
            <a href="" className="dropdown-item" onClick={()=>{
              closeProfile()
            }}>
              <span>
                {t('header.signedAs')}<br />
                <b>{auth()?.sub}</b>
              </span>
            </a>
            <a href="" className="dropdown-item" onClick={()=>{
              navigate('/profile')
              closeProfile()
            }}>
              <span>
                {t('header.profile.title')}<br />
                {t('header.profile.subtitle')}
              </span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="" onClick={()=>{
              logout()
              closeProfile()
            }} className="dropdown-item">
              {t('header.logout')}
            </a>
            <div className="dropdown-divider"></div>
            <span className="dropdown-item fs-12 hint-text">
              {t('header.lastLogin')}  
              <br/>
              {new Date(auth()?.iat * 1000).toLocaleString('cs-CZ', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
})}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Component