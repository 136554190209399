import axios, { AxiosError } from "axios"
import { Form, Formik } from "formik"
import { loginApiClient } from "../../api/calls"
import Input from "../../components/elements/Input"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import QRCode from 'qrcode.react';
import { useNavigate } from "react-router-dom"
import Alert from "../../components/elements/Alert"
import * as Yup from 'yup';
import jwtDecode from "jwt-decode"
import { useSignIn } from "react-auth-kit"
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/spinner';
import { useState } from "react"


const TotpSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]{6}$/, 'login.error.verificationLength')
    .required("login.error.verificationRequired")
})

const Component: React.FC<{
  totpToken: string,
  setVerificationToken: Function
  loginValues: any
}> = ({totpToken, setVerificationToken, loginValues}) => {

  const { t } = useTranslation();
  const signIn = useSignIn()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [autoLogin, setAutoLogin] = useState(false)

  const {data, error, isError} = useQuery('totpConfig', async () => {
    try {
      const response = await loginApiClient.post(
        '/2fa/account/config/generate',
        {}, // has to be empty
        {
          headers: {
            'Authorization': `Bearer ${totpToken}`
          },
          params: {
            providerType: 'TOTP',
          }
        },
      )
      return response.data
    } catch (error) {
      // Return a rejected promise so useQuery knows the request failed
      return Promise.reject(error);
    }
  })

  const checkTotp = async (code: any) => {
    await loginApiClient.post(
      '/2fa/account/config',
      {
        providerType: 'TOTP',
        authUrl: data?.authUrl,
        useByDefault: true
      }, 
      {
        headers: {
          'Authorization': `Bearer ${totpToken}`
        },
        params: {
          verificationCode: code
        }
      },
    )

    setAutoLogin(true)
  }

  const doLogin = async (code: any)  => {
    const login = await loginApiClient.post(
      '/auth/login',
      loginValues,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    const response = await loginApiClient.post(
      '/auth/2fa/verification/check',
      {}, // has to be empty
      {
        headers: {
          'Authorization': `Bearer ${login.data.token}`
        },
        params: {
          providerType: 'TOTP',
          verificationCode: code
        }
      },
    )

    const decodedToken = jwtDecode(response.data.token) as any

    signIn({
      token: response.data.token,
      expiresIn: 3600 * 24,
      tokenType: "Bearer",
      refreshToken: response.data.refreshToken,
      refreshTokenExpireIn: 3600 * 24,
      authState: decodedToken
    })
    setVerificationToken("")
    navigate("/")
   
  }

  return (
    <Formik
      initialValues={{ 
        code: '', 
      }}
      validationSchema={TotpSchema}
      onSubmit= { async (values: any, actions)=> {
        dispatch(startLoading());
        const { code } = values
        try {
          await checkTotp(code)
          await doLogin(code)

        } catch (error: AxiosError|any) {
          actions.resetForm()
        } finally {
          actions.setSubmitting(false)
          dispatch(stopLoading());
        }
      }}
   >
    {({ isSubmitting, isValid, values }) => (
      <>
      <p className="mw-80 m-t-5">{t("login.totp.config.title")}</p>
      <div className="p-5">
        { 
          autoLogin  
            ? <h2 >{t("login.totp.autologin")}</h2> 
            : <QRCode 
            value={data?.authUrl} 
            size={196}
            className="mx-auto d-block"
            />
        }
       
      </div>
        <Alert type="danger" text={(error as any)?.message}/>
        {!autoLogin &&
          <Form className="p-t-15">
            <Input
              label="Verification Code"
              name="code"
              type="text"
              placeholder="i.e 880417"
              className="form-control"
              required
              autoFocus
            />
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                <button
                  aria-label=""
                  className="btn btn-complete btn-lg m-t-10"
                  type="submit"
                  disabled={values.code.length == 0 || !isValid || isSubmitting}
                >
                {t("login.totp.submit")}
                </button>
              </div>
            </div>
          </Form>
        }
      </>
      )}
    </Formik>

  )
}

export default Component