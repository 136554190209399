import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { useMemo } from "react";

const useAdminAmbulancesTableColumns = ()=> {
  const { t } = useTranslation();
  const columns = useMemo(()=> [
    {
      id: 'id',
      accessorFn: (row: any)=>{
        return row.id
      }
    },
    {
      id: 'name',
      Header: t("admin.ambulances.table.name"),
      accessorFn: (row: any)=>{
        return row.first_name + ' ' + row.last_name
      }
    },
    {
      id: 'email',
      Header: t("admin.ambulances.table.email"),
      accessorFn: (row: any)=>{
        return row.email
      }
    },
    {
      id: 'sms',
      Header: t("admin.ambulances.table.sms"),
      accessorFn: (row: any)=>{
        return row.sms
      }
    },
    {
      id: 'actions',
      Header: t("admin.ambulances.table.actions"),
      className: 'shrink',
      Cell:  ({cell:{row}}:any) => (
        <div className='table-actions'>
          <span data-tooltip-id={"tooltip-edit"+row.values.id} >
            <button
              className='btn btn-primary'
              onClick={()=>{}}
            >
              <Icon>edit</Icon>
            </button>
          </span>
          <Tooltip id={"tooltip-edit"+row.values.id} place="top">
            {t('admin.ambulances.action.edit')}
          </Tooltip>
        </div>
      )
    }
  ], [])

  return columns;

}

export default useAdminAmbulancesTableColumns