import { Route, Routes } from "react-router-dom";
import PageLayout from "../components/layout/Page";
import AdminCaretakersPage from "../pages/Caretakers/CaretakersPage";
import AdminAmbulancesPage from "../pages/AdminAmbulances/AdminAmbulancesPage";
import AdminPatientsPage from "../pages/AdminPatients/AdminPatientsPage";
import NotFound from "../pages/NotFound";

const Dashboard = ()=> {
  return (
    <PageLayout className="gutter-sm">
      <div className="container padding-25 sm-padding-10">
        Admin
      </div>
    </PageLayout>
  )
}

const AdminRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/admin/caretakers" element={<AdminCaretakersPage />}></Route>
        <Route path="/admin/ambulances" element={<AdminAmbulancesPage />}></Route>
        <Route path="/admin/patients" element={<AdminPatientsPage />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    );
}

export default AdminRoutes;