import React, { useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store'; 

// replace these with the actions you define in your Redux slice
import { startLoading, stopLoading } from '../../redux/spinner'; 

const FullScreenSpinner: React.FC<{
  timeout?: number;
}> = ({ timeout = 20000 }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.spinner.loading);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(stopLoading());
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, dispatch]);

  return (
    loading ? (
      <div className="spinner">
        <ClipLoader color="#ffffff" size={150} />
      </div>
    ) : null
  );
}

export default FullScreenSpinner;
