// columnsDefinition.ts
import { useTranslation } from "react-i18next";
import Icon from "../../components/elements/Icon";
import { Tooltip } from 'react-tooltip';
import { useMemo } from "react";
import { Patient } from "../../types";

const usePatientsTableColumns = (
  handleViewTelemetry: (deviceId: string, patientId: string, deviceType: string, name: string, timestamp?: string) => void,
  handleAlarmSetup: (deviceId: string, deviceType: string, name: string) => void,
) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'id',
        accessorKey: 'id'
      },
      {
        id: 'device_id',
        accessorFn: (row: Patient) => {
          return row.sys_assignment_devID
        }
      },
      {
        id: 'name',
        header: t("patients.table.name"),
        accessorKey: 'name',
      },
      {
        id: 'ambulance',
        header: t("patients.table.ambulance"),
        accessorFn: (row: Patient) => {
          return row.ambulance || '-'
        }
      },
      {
        id: 'caretakers',
        header: t("patients.table.caretakers"),
        accessorFn: (row: Patient) => {
          return row.caretakers?.join(", ") || '-'
        }
      },
      {
        id: 'device',
        header: t("patients.table.device"),
        accessorFn: (row: Patient) => {
          return row.device?.label || row.device?.name || '-'
        },
      },
      {
        id: 'device_type',
        header: t("patients.table.device_type"),
        accessorFn: (row: Patient) => {
          return row.device?.type || 'LW-360HR'
        }
      },
      {
        id: 'timestamp',
        header: t("patients.table.timestamp"),
        accessorFn: (row: Patient) => {
          return row.device?.ts ? new Date(row.device?.ts).toLocaleString('cs-CZ', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}) : "-"
        },
      },
      {
        id: 'alarms',
        header: t("patients.table.alarms"),
        className: 'center',
        accessorFn: (row: Patient) => {
          return row.alarms ? "Ano" : "Ne"
        },
      },
      {
        id: 'actions',
        header: t("patients.table.actions"),
        accessorKey: "actions",
        className: 'shrink',
        cell: (info: any) => {
          const device_id = info.row.getValue('device_id')
          const device_type = info.row.getValue('device_type')
          const id = info.row.getValue('id')
          const name = info.row.getValue('name')
          return (
            <div className="table-actions">
              <span data-tooltip-id={"tooltip-view" + id} >
                <button
                  className='btn btn-primary'
                  disabled={!device_id}
                  onClick={() => handleViewTelemetry(device_id, id, device_type, name)}
                >
                  <Icon>eye</Icon>
                </button>
              </span>
              <Tooltip className="tooltip-visible" id={"tooltip-view" + id} place="top">{t('patients.action.view')}</Tooltip>
              <span data-tooltip-id={"tooltip-alarms" + id}>
                <button
                  className='btn btn-complete'
                  disabled={!device_id}
                  onClick={() => handleAlarmSetup(id, device_type, name)}>
                  <Icon>{'warning'}</Icon>
                </button>

              </span>
              <Tooltip className="tooltip-visible" id={"tooltip-alarms" + id} place="top">
                {t('patients.action.alarms')}
              </Tooltip>
            </div>

          )
        }

      }
    ],
    [t, handleViewTelemetry, handleAlarmSetup]
  )

  return columns;
};

export default usePatientsTableColumns;
