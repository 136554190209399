import { Form, Formik, useFormikContext } from "formik"
import Datetime from "../../components/elements/Datetime"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import * as Yup from 'yup';


const validationSchema = Yup.object().shape({
  start: Yup.date()
    .required("patients.telemetry.error.startRequired")
    .when('end', ([end], schema)=>{
      return end && schema.max(end, "patients.telemetry.error.highStart")
    }),
  end: Yup.date()
    .required("patients.telemetry.error.endRequired")
    .test('isFuture', "patients.telemetry.error.highEnd", value => {
      const dt = new Date();
      dt.setMinutes(dt.getMinutes() + 5);
      return value <= dt;
    })
});

const AutoSubmit = ()=>{
  const { values, submitForm, isValid } = useFormikContext<{start: string, end: string}>();
  useEffect(() => {
    if(isValid) {
      submitForm();
    }
  }, [values, submitForm, isValid]);
  return null
}

const format = (dateObj: Date): string => {
  if(!dateObj) return ""
  let year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  let day = dateObj.getDate().toString().padStart(2, '0');
  let hours = dateObj.getHours().toString().padStart(2, '0');
  let minutes = dateObj.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}


const Component: React.FC<{
  setTimeFilter: (values: {start: Date, end: Date}) => void,
  start: Date,
  end: Date
}> = ({setTimeFilter, start, end}) => {

  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{
        start: format(start),
        end: format(end)
      }}
      onSubmit={(values) => {
        setTimeFilter({
          start: new Date(values.start),
          end: new Date(values.end)
        })
      }}
      validationSchema={validationSchema}
    >
      <>
      <Form className="d-flex flex-lg-row flex-column justify-content-lg-end">

        <div className="m-r-20">
          <Datetime
            label={t('patients.telemetry.startLabel')}
            type="datetime-local"
            name="start"
          />

        </div>
        <div className="">
          <Datetime
            label={t('patients.telemetry.endLabel')}
            type="datetime-local"
            name="end"
          />
        </div>
        <AutoSubmit />
      </Form>
      </>
    </Formik>
  )
}

export default Component